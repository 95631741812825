import React, { useRef, useState } from 'react';
import { takeScreenshot } from '../../../../config/helper-functions';
import { useImageDimensions } from '../../../../hooks/useImageDimensions';
import { DraggableItem } from './DraggableItem';

const MemeCanvas = ({ imageUrl, topText, bottomText, topTextScale, bottomTextScale, props }) => {
	const canvasRef = useRef(null);
	const dimensions = useImageDimensions(imageUrl);
	const [isCapturing, setIsCapturing] = useState(false);

	const handleDownload = async () => {
		setIsCapturing(true);
		try {
			await takeScreenshot(canvasRef.current);
		} finally {
			setIsCapturing(false);
		}
	};

	if (!dimensions) {
		return null;
	}

	return (
		<div className='relative'>
			<div
				ref={canvasRef}
				className='relative mx-auto bg-black rounded-lg overflow-hidden w-fit'
				style={{
					height: dimensions.displayHeight,
				}}
			>
				<img
					src={imageUrl}
					alt='Meme base'
					width={dimensions.displayWidth}
					height={dimensions.displayHeight}
					className='w-full h-full object-contain'
					crossOrigin='anonymous'
				/>

				{topText && (
					<DraggableItem
						initialPosition={{ x: 50, y: 20 }}
						scale={topTextScale}
						className={isCapturing ? 'no-controls' : ''}
					>
						<h2 className='text-4xl font-bold text-white [text-shadow:_2px_2px_0_rgb(0_0_0)]'>
							{topText}
						</h2>
					</DraggableItem>
				)}

				{bottomText && (
					<DraggableItem
						initialPosition={{
							x: 50,
							y: dimensions.displayHeight - 100,
						}}
						scale={bottomTextScale}
						className={isCapturing ? 'no-controls' : ''}
					>
						<h2 className='text-4xl font-bold text-white [text-shadow:_2px_2px_0_rgb(0_0_0)]'>
							{bottomText}
						</h2>
					</DraggableItem>
				)}

				{props.map((prop) => (
					<DraggableItem
						key={prop.id}
						scale={prop.scale}
						className={isCapturing ? 'no-controls' : ''}
					>
						{prop.type === 'image' ? (
							<img
								src={prop.url}
								alt={prop.type}
								className='w-24 h-24 object-contain'
								crossOrigin='anonymous'
							/>
						) : (
							<div
								className={`text-2xl font-bold ${
									prop.textColor || 'text-white'
								} [text-shadow:_2px_2px_0_rgb(0_0_0)]`}
							>
								{prop.text}
							</div>
						)}
					</DraggableItem>
				))}
			</div>
			<div className='flex justify-center pb-4'>
				<button
					onClick={handleDownload}
					disabled={isCapturing}
					className='mx-auto mt-4 px-6 py-2 bg-yellow-400 text-black font-mono rounded hover:bg-yellow-500 transition-colors disabled:opacity-50'
				>
					{isCapturing ? 'Capturing...' : 'Download Meme'}
				</button>
			</div>
		</div>
	);
};

export default MemeCanvas;
