import { ethers, formatUnits } from 'ethers';
import config from '../config/config.wods';
import { TOKEN_CONTRACT_ABI } from '../config/abi/token.abi';
import { ADMIN_FACTORY_ABI } from '../config/abi/admin.abi';
import { PREDICTO_ABI } from '../config/abi/predicto.abi';

const BATCH_SIZE = 5;
const provider = new ethers.JsonRpcProvider(config.FETCH_TRANSACTION_RPC);

const getContract = (address, abi) =>
    new ethers.Contract(address, abi, provider);

const isWinningStatus = (statusCode) =>
    ({ 1: 'Won', 2: 'Lost' }[statusCode] || 'Not decided');

export const fetchWodsBalance = async (walletAddress) => {
    try {
        const tokenContract = getContract(
            config.WOODS_TOKEN,
            TOKEN_CONTRACT_ABI
        );
        const rawBalance = await tokenContract.balanceOf(walletAddress);
        return Number(formatUnits(rawBalance, 18)).toFixed(5);
    } catch (error) {
        console.error('Error fetching $WODS balance:', error);
        return '0';
    }
};

export const fetchEthBalance = async (walletAddress) => {
    try {
        const balance = await provider.getBalance(walletAddress);
        return Number(ethers.formatUnits(balance, 18)).toFixed(8);
    } catch (error) {
        console.error('Error fetching $ETH balance:', error);
        return '0';
    }
};

const fetchMarketData = async (address, walletAddress) => {
    try {
        const predictionContract = getContract(address, PREDICTO_ABI);
        const [userBetOutcome, userBetAmount, marketDetails, outcomeDetails] =
            await Promise.all([
                predictionContract.userBets(walletAddress),
                predictionContract.userAmounts(walletAddress),
                predictionContract.market(),
                predictionContract.outcomes(0),
            ]);

        if (userBetAmount.toString() === '0') return null;

        const marketInfo = JSON.parse(marketDetails[2]);
        const endTime = parseInt(marketDetails[10]) * 1000;
        const currentTime = Date.now();
        const isSettled = marketDetails[13];
        const finalOutcome = isSettled ? parseInt(marketDetails[12]) : null;

        let status = 'Result not declared';
        if (currentTime > endTime) {
            const participants = finalOutcome
                ? await predictionContract.getParticipants(finalOutcome)
                : [];
            status = participants.some(
                (addr) => addr.toLowerCase() === walletAddress.toLowerCase()
            )
                ? 'Won'
                : 'Lost';
        }

        return {
            title: marketInfo.name,
            description: marketInfo.description,
            category: marketInfo.category,
            image: `https://${config.PINATA_GATEWAY}/ipfs/${marketInfo['image-hash']}`,
            date: new Date(endTime),
            marketAddress: address,
            marketTitle: marketDetails.description,
            betOutcome: userBetOutcome.toString(),
            betOutcomeName: outcomeDetails.name,
            betAmount: ethers.formatUnits(userBetAmount, 18),
            address,
            status,
        };
    } catch (error) {
        console.error(`Error fetching market data for ${address}:`, error);
        return null;
    }
};

export const fetchParticipatedMarkets = async (walletAddress) => {
    try {
        const factoryContract = getContract(
            config.FACTORY_CONTRACT_ADDRESS,
            ADMIN_FACTORY_ABI
        );
        const marketAddresses = await factoryContract.getAllMarkets();

        const userBetsData = await Promise.all(
            marketAddresses.map((address) =>
                fetchMarketData(address, walletAddress)
            )
        );

        return userBetsData.filter(Boolean);
    } catch (error) {
        console.error('Error fetching participated markets:', error);
        return [];
    }
};

export const fetchPredictions = async () => {
    try {
        const factoryContract = getContract(
            config.FACTORY_CONTRACT_ADDRESS,
            ADMIN_FACTORY_ABI
        );
        const marketAddresses = await factoryContract.getAllMarkets();

        const addressBatches = Array.from(
            { length: Math.ceil(marketAddresses.length / BATCH_SIZE) },
            (_, i) =>
                marketAddresses.slice(i * BATCH_SIZE, (i + 1) * BATCH_SIZE)
        );

        const marketData = [];
        await Promise.all(
            addressBatches.map((batch) =>
                Promise.all(
                    batch.map(async (address) => {
                        try {
                            const predictionContract = getContract(
                                address,
                                PREDICTO_ABI
                            );
                            const [marketDetails, outcomes] = await Promise.all(
                                [
                                    predictionContract.market(),
                                    Promise.all([
                                        predictionContract.outcomes(0),
                                        predictionContract.outcomes(1),
                                    ]),
                                ]
                            );

                            const marketInfo = JSON.parse(marketDetails[2]);
                            const endTime = parseInt(marketDetails[10]) * 1000;
                            const status =
                                Date.now() < endTime
                                    ? 0
                                    : marketDetails[13]
                                    ? parseInt(marketDetails[12])
                                    : null;

                            marketData.push({
                                admin: marketDetails[0],
                                title: marketInfo.name,
                                description: marketInfo.description,
                                category: marketInfo.category,
                                options: outcomes,
                                image: marketInfo['image-hash']
                                    ? `https://${config.PINATA_GATEWAY}/ipfs/${marketInfo['image-hash']}`
                                    : null,
                                minPrice: Number(
                                    formatUnits(marketDetails.minPrice, 18)
                                ),
                                maxPrice: Number(
                                    formatUnits(marketDetails.maxPrice, 18)
                                ),
                                date: new Date(endTime),
                                totalBets: parseInt(marketDetails[8]),
                                totalRewards: parseFloat(
                                    formatUnits(marketDetails[7], 18)
                                ),
                                address,
                                status: isWinningStatus(status),
                            });
                        } catch (error) {
                            console.error(
                                `Error fetching market data for ${address}:`,
                                error
                            );
                        }
                    })
                )
            )
        );

        return marketData;
    } catch (error) {
        console.error('Error fetching predictions:', error);
        return [];
    }
};

export const fetchProfilePageData = async (walletAddress) => {
    try {
        const [wodsBalance, ethBalance, predictions, participatedMarkets] =
            await Promise.all([
                fetchWodsBalance(walletAddress),
                fetchEthBalance(walletAddress),
                fetchPredictions(),
                fetchParticipatedMarkets(walletAddress),
            ]);

        const createdMarkets = predictions.filter(
            (market) =>
                market.admin?.toLowerCase() === walletAddress.toLowerCase()
        );

        return {
            wodsBalance,
            ethBalance,
            createdMarkets: createdMarkets.reverse(),
            participatedMarkets: participatedMarkets.reverse(),
        };
    } catch (error) {
        console.error('Error fetching profile page data:', error);
        return null;
    }
};
