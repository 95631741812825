import { Clock, ImagePlus } from 'lucide-react';
import React, { useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { CHAIN_CONFIG } from '../../../../config/config.wods';
import writeToContract from '../../../../services/api.writeContract';
import AnnounceCampaign from './AnnounceCampaign/index';

const CampaignFormUI = () => {
    const [imagePreview, setImagePreview] = useState('');
    const [selected, setSelected] = useState(0);
    const [formData, setFormData] = useState({
        image: null,
        name: '',
        description: '',
        outcomes: ['Yes', 'No'],
        referenceLink: '',
        minBet: '',
        maxBet: '',
        endTime: '',
        category: '',
    });

    const categories = [
        'crypto',
        'technology',
        'culture',
        'politics',
        'sports',
        'breaking',
    ];

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    const customToastStyle = {
        style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
        },
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.size > 2 * 1024 * 1024) {
                // 2MB size limit
                toast.error(
                    'Image size should be less than 2MB',
                    customToastStyle
                );
                return;
            }
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
            setFormData((prev) => ({ ...prev, image: file }));
        }
    };

    const handleDeployContract = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');

        try {
            const {
                image,
                name,
                description,
                outcomes,
                endTime,
                minBet,
                maxBet,
                category,
            } = formData;

            // Validate required fields
            if (
                !name ||
                !description ||
                !outcomes.length ||
                !endTime ||
                !minBet ||
                !maxBet
            ) {
                throw new Error('Please fill out all fields correctly.');
            }

            // Validate outcomes
            if (outcomes.some((outcome) => !outcome.trim())) {
                throw new Error('Outcomes cannot be empty.');
            }

            // Convert end time to a timestamp
            const endTimestamp = Math.floor(new Date(endTime).getTime() / 1000);

            // Call the writeToContract function

            const DescriptionJSON = {
                name,
                description,
                category,
            };

            const result = await writeToContract(
                image,
                DescriptionJSON,
                outcomes,
                18, // Token decimal, assuming 18 for WOODS_TOKEN
                endTimestamp,
                parseFloat(minBet),
                parseFloat(maxBet)
            );
            // Success handling
            if (result) {
                const trimmedAddress =
                    result.hash.length > 20
                        ? `${result.hash.slice(0, 6)}...${result.hash.slice(
                              -4
                          )}` // Trim to 20 characters
                        : result.hash;
                toast.success(
                    <div>
                        Campaign deployed successfully! <br />
                        <a
                            href={`${CHAIN_CONFIG.blockExplorerUrls[0]}tx/${result.hash}`} // Replace with your actual explorer URL
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-400 underline"
                        >
                            {trimmedAddress}
                        </a>
                    </div>,
                    {
                        ...customToastStyle,
                        duration: 10000,
                    }
                );

                // Reset form after successful deployment
                setFormData({
                    image: null,
                    name: '',
                    description: '',
                    outcomes: ['Yes', 'No'],
                    referenceLink: '',
                    minBet: '',
                    maxBet: '',
                    endTime: '',
                });
                setImagePreview('');
            }
        } catch (err) {
            // Error handling
            const errorMessage =
                'Failed to deploy campaign, you might not be whitelisted contact admin.';
            setError(errorMessage);
            toast.error('Something went wrong.', customToastStyle);
            console.error('Deployment error:', errorMessage);
        } finally {
            setIsLoading(false);
        }
    };
    return (
        <div className="rounded-lg shadow-xl p-4 pt-8">
            <div className="flex items-left gap-4 md:flex-row flex-col justify-start border-gray-700 bg-gray-900 p-4 mb-8 rounded-lg">
                {/* Tab 1 */}
                <button
                    className={`text-lg font-semibold px-6 py-2 transition-all duration-300 border-r-yellow-600 border-r ${
                        selected === 0
                            ? 'text-yellow-700 hover:text-white'
                            : ' text-gray-400 hover:text-white'
                    }`}
                    onClick={() => setSelected(0)}
                >
                    Create Campaign
                </button>

                {/* Tab 2 */}
                <button
                    className={`text-lg font-semibold px-6 py-2 rounded-lg transition-all duration-300 ${
                        selected === 1
                            ? 'text-yellow-700'
                            : 'text-gray-400 hover:text-white'
                    }`}
                    onClick={() => setSelected(1)}
                >
                    Announce Results
                </button>
            </div>

            {selected === 0 ? (
                <form
                    onSubmit={handleDeployContract}
                    className="grid grid-cols-1 lg:grid-cols-2 gap-8"
                >
                    {/* Left Column - Image and Campaign Details */}
                    <div className="space-y-6 flex flex-col">
                        {/* Image Upload - Increased prominence */}
                        <div className="flex-grow">
                            <label className="block text-sm font-medium text-gray-200 mb-2">
                                Campaign Image
                            </label>
                            <div className="border-2 border-dashed border-gray-600 rounded-lg p-4 text-center h-full min-h-[200px] flex items-center justify-center">
                                <input
                                    type="file"
                                    id="image"
                                    accept="image/*"
                                    onChange={handleImageChange}
                                    className="hidden"
                                />
                                <label
                                    htmlFor="image"
                                    className="cursor-pointer block w-full h-full"
                                >
                                    {imagePreview ? (
                                        <img
                                            src={imagePreview}
                                            alt="Preview"
                                            className="max-h-full mx-auto rounded-lg object-contain"
                                        />
                                    ) : (
                                        <div className="flex flex-col justify-center items-center h-full">
                                            <ImagePlus className="h-12 w-12 mx-auto text-gray-400" />
                                            <span className="text-sm text-gray-400 block mt-2">
                                                Click to upload image (max 5MB)
                                            </span>
                                        </div>
                                    )}
                                </label>
                            </div>
                        </div>

                        {/* Campaign Name */}
                        <div>
                            <label className="block text-sm font-medium text-gray-200 my-4">
                                Campaign Name
                            </label>
                            <input
                                type="text"
                                placeholder="Enter campaign name"
                                value={formData.name}
                                onChange={(e) =>
                                    setFormData((prev) => ({
                                        ...prev,
                                        name: e.target.value,
                                    }))
                                }
                                className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-200"
                                required
                            />
                        </div>

                        {/* Outcomes - Expanded to fill more space */}
                        <div className="flex-grow">
                            <label className="block text-sm font-medium text-gray-200 mb-2">
                                Outcomes
                            </label>
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 h-full">
                                {formData.outcomes.map((outcome, index) => (
                                    <input
                                        key={index}
                                        value={outcome}
                                        onChange={(e) => {
                                            const newOutcomes = [
                                                ...formData.outcomes,
                                            ];
                                            newOutcomes[index] = e.target.value;
                                            setFormData((prev) => ({
                                                ...prev,
                                                outcomes: newOutcomes,
                                            }));
                                        }}
                                        placeholder={`Outcome ${index + 1}`}
                                        className="px-4 py-2 bg-gray-800 border border-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-200 h-12"
                                        required
                                    />
                                ))}
                            </div>
                        </div>
                    </div>

                    {/* Right Column - Outcomes and Additional Details */}
                    <div className="space-y-6 flex flex-col">
                        {/* Campaign Description */}
                        <div>
                            <label className="block text-sm font-medium text-gray-200 mb-2">
                                Campaign Description
                            </label>
                            <textarea
                                placeholder="Enter campaign description"
                                value={formData.description}
                                onChange={(e) =>
                                    setFormData((prev) => ({
                                        ...prev,
                                        description: e.target.value,
                                    }))
                                }
                                className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 min-h-[150px] text-gray-200"
                                required
                            />
                        </div>

                        {/* Dropdown for Categories */}
                        <div>
                            <label className="block text-sm font-medium text-gray-200 mb-2">
                                Select Category
                            </label>
                            <select
                                value={formData.category}
                                onChange={(e) =>
                                    setFormData((prev) => ({
                                        ...prev,
                                        category: e.target.value,
                                    }))
                                }
                                className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-200"
                                required
                            >
                                <option value="" disabled>
                                    Choose a category
                                </option>
                                {categories.map((category, index) => (
                                    <option key={index} value={category}>
                                        {category.charAt(0).toUpperCase() +
                                            category.slice(1)}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {/* Prediction End Time */}
                        <div>
                            <label className="block text-sm font-medium text-gray-200 mb-2">
                                Prediction End Time
                            </label>
                            <div className="relative">
                                <Clock className="absolute left-3 top-3 h-4 w-4 text-gray-400" />
                                <input
                                    type="datetime-local"
                                    value={formData.endTime}
                                    onChange={(e) =>
                                        setFormData((prev) => ({
                                            ...prev,
                                            endTime: e.target.value,
                                        }))
                                    }
                                    className="w-full pl-10 pr-4 py-2 bg-gray-800 border border-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-200"
                                    required
                                />
                            </div>
                        </div>
                        {/* Bet Amounts */}
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                            <div>
                                <label className="block text-sm font-medium text-gray-200 mb-2">
                                    Minimum Bet
                                </label>
                                <div className="relative">
                                    <span className="absolute left-3 top-[10px] text-gray-400">
                                        $WODS
                                    </span>
                                    <input
                                        type="number"
                                        step="0.01"
                                        placeholder="0.00"
                                        value={formData.minBet}
                                        onChange={(e) =>
                                            setFormData((prev) => ({
                                                ...prev,
                                                minBet: e.target.value,
                                            }))
                                        }
                                        className="w-full pl-20 pr-4 py-2 bg-gray-800 border border-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-200"
                                        required
                                    />
                                </div>
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-200 mb-2">
                                    Maximum Bet
                                </label>
                                <div className="relative">
                                    <span className="absolute left-3 top-[10px] text-gray-400">
                                        $WODS
                                    </span>
                                    <input
                                        type="number"
                                        step="0.01"
                                        placeholder="0.00"
                                        value={formData.maxBet}
                                        onChange={(e) =>
                                            setFormData((prev) => ({
                                                ...prev,
                                                maxBet: e.target.value,
                                            }))
                                        }
                                        className="w-full pl-20 pr-4 py-2 bg-gray-800 border border-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-200"
                                        required
                                    />
                                </div>
                            </div>
                        </div>

                        {/* Error Display */}
                        {error && (
                            <div className="bg-red-900 border-l-4 border-red-500 p-4">
                                <p className="text-red-200">{error}</p>
                            </div>
                        )}

                        {/* Submit Button */}
                        <button
                            type="submit"
                            className="w-full bg-yellow-700 text-white py-3 px-4 rounded-md hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-600 transition-colors duration-200"
                            disabled={isLoading}
                        >
                            {isLoading
                                ? 'Deploying...'
                                : 'Create Prediction Campaign'}
                        </button>
                    </div>
                    <Toaster />
                </form>
            ) : (
                <AnnounceCampaign />
            )}
        </div>
    );
};

export default CampaignFormUI;
