import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ThemeContextProvider } from './views/components/context/ThemeContext';
import CampaignForm from './views/pages/CreatePrediction';
import Meme from './views/pages/Meme';
import Campaigns from './views/pages/Prediction';
// import MarketPage from './views/pages/Prediction/components/MarketPage';
import Footer from './views/components/Footer';
import Header from './views/components/Header';
import MarketPage from './views/pages/Prediction/components/MarketPage';
import { ProfilePage } from './views/pages/Profile';

export default function App() {
    return (
        <ThemeContextProvider>
            <BrowserRouter>
                <Header />
                <Routes>
                    <Route
                        path="/"
                        element={<Navigate to="/predict" replace />}
                    />
                    <Route path="/predict" element={<Campaigns />}>
                        <Route path=":address" element={<MarketPage />} />
                    </Route>
                    <Route path="/meme" element={<Meme />} />
                    <Route
                        path="/create-prediction"
                        element={<CampaignForm />}
                    />
                    <Route path="/profile" element={<ProfilePage />} />
                </Routes>
                <Footer />
            </BrowserRouter>
        </ThemeContextProvider>
    );
}
