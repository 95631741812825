import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import CURR_URL from '../../config/config.wods';

export const communitiesApi = createApi({
	reducerPath: 'api',
	baseQuery: fetchBaseQuery({
		baseUrl: CURR_URL.endpoint,
		prepareHeaders: async (headers) => {
			const token = localStorage.getItem('token');
			const source = localStorage.getItem('source');
			if (token && source) {
				headers.set('source', source);
				headers.set('Authorization', `Bearer ${token}`);
			}
			return headers;
		},
	}),
	tagTypes: [
		'AllCommunities',
		'AllProfiles',
		'AllEvents',
		'AllTeams',
		'AllResults',
		'AllPrediction',
	],
	endpoints: (builder) => ({
		isAdmin: builder.query({
			query: () => ({
				url: `admin/login`,
				method: 'GET',
			}),
			transformResponse: (response) => response.data,
			providesTags: [`AllCommunities`],
		}),
		getCommunities: builder.query({
			query: (data) => ({
				url: `admin/communities?limit=10&page=${data}`,
				method: 'GET',
			}),
			transformResponse: (response) => response.data,
			providesTags: [`AllCommunities`],
		}),
		updateAdminCommunity: builder.mutation({
			query: (data) => ({
				url: 'admin/communities',
				method: 'PUT',
				body: data,
			}),
			invalidatesTags: [`AllCommunities`],
		}),
		deleteCommunity: builder.mutation({
			query: (data) => ({
				url: `admin/communities?community_uid=${data}`,
				method: 'DELETE',
				body: data,
			}),
			invalidatesTags: [`AllCommunities`],
		}),
		searchCommunity: builder.query({
			query: ({ query_value }) => ({
				url: 'admin/communities/search?query_value=' + query_value,
				method: 'GET',
			}),
			transformResponse: (response) => response.data,
		}),
		getProfiles: builder.query({
			query: (data) => ({
				url: `admin/users?limit=10&page=${data}`,
				method: 'GET',
			}),
			transformResponse: (response) => response.data,
			providesTags: [`AllProfiles`],
		}),
		searchUsers: builder.query({
			query: ({ query_value }) => ({
				url: 'admin/users/search?query_value=' + query_value,
				method: 'GET',
			}),
			transformResponse: (response) => response.data,
		}),
		deactiveUserProfile: builder.mutation({
			query: (data) => ({
				url: `admin/user/status`,
				method: 'PUT',
				body: data,
			}),
			transformResponse: (response) => response,
			invalidatesTags: [`AllProfiles`],
		}),
		updateAdminUsers: builder.mutation({
			query: (data) => ({
				url: 'admin/users',
				method: 'PUT',
				body: data,
			}),
			transformResponse: (response) => response,
			invalidatesTags: [`AllProfiles`],
		}),
		airdropTurfXp: builder.mutation({
			query: (data) => ({
				url: 'admin/airdrop_xp',
				method: 'POST',
				body: data,
			}),
			transformResponse: (response) => response,
			invalidatesTags: [`AllProfiles`],
		}),
		airdropReferral: builder.mutation({
			query: (data) => ({
				url: 'admin/airdrop_referral',
				method: 'POST',
				body: data,
			}),
			transformResponse: (response) => response,
			invalidatesTags: [`AllProfiles`],
		}),
		createEvent: builder.mutation({
			query: (data) => ({
				url: 'admin/event',
				method: 'POST',
				body: data,
			}),
			transformResponse: (response) => response,
			invalidatesTags: [`AllEvents`],
		}),
		fetchAllEvents: builder.query({
			query: (data) => ({
				url: `admin/events?limit=10&page=${data}`,
				method: 'GET',
			}),
			transformResponse: (response) => response.data,
			providesTags: [`AllEvents`],
		}),
		deleteEvents: builder.mutation({
			query: (data) => ({
				url: `admin/event?event_id=${data}`,
				method: 'DELETE',
				body: data,
			}),
			transformResponse: (response) => response,
			invalidatesTags: [`AllEvents`],
		}),
		updateEventDetails: builder.mutation({
			query: (data) => ({
				url: `admin/event?event_id=${data.id}`,
				method: 'PUT',
				body: data.data,
			}),
			invalidatesTags: [`AllEvents`],
		}),
		fetchAllTeams: builder.query({
			query: (id) => ({
				url: `admin/event/teams?eventId=${id}`,
				method: 'GET',
			}),
			transformResponse: (response) => response.data,
		}),
		fetchAllLobbies: builder.query({
			query: (id) => ({
				url: `admin/event/lobby?eventId=${id}`,
				method: 'GET',
			}),
			transformResponse: (response) => response.data,
		}),
		createAnnouncements: builder.mutation({
			query: (data) => ({
				url: 'admin/event/announcement',
				method: 'POST',
				body: data,
			}),
			transformResponse: (response) => response,
			invalidatesTags: [`AllTeams`],
		}),
		udpateAnnouncements: builder.mutation({
			query: (data) => ({
				url: 'admin/event/announcement',
				method: 'PUT',
				body: data,
			}),
			transformResponse: (response) => response,
			invalidatesTags: [`AllTeams`],
		}),
		fetchAnnoucements: builder.query({
			query: ({ event_id, team_id, lobby_id, level }) => ({
				url: `admin/event/announcement?eventId=${event_id}&level=${level}&${
					lobby_id ? `lobbyId=${lobby_id}` : team_id ? `teamId=${team_id}` : ''
				}`,
				method: 'GET',
			}),
			transformResponse: (response) => response.data,
			providesTags: [`AllTeams`],
		}),
		deleteAnnoucements: builder.mutation({
			query: (id) => ({
				url: `admin/event/announcement?announcement_id=${id}`,
				method: 'DELETE',
			}),
			transformResponse: (response) => response,
			invalidatesTags: [`AllTeams`],
		}),
		fetchResults: builder.query({
			query: ({ roundNo, id, lobbyId }) => ({
				url: `admin/events/level_lobby?level=${roundNo}&eventId=${id}&lobbyId=${lobbyId}`,
				method: 'GET',
			}),
			transformResponse: (response) => response.data,
			providesTags: ['AllResults'],
		}),
		addResults: builder.mutation({
			query: (data) => ({
				url: 'admin/event/update_team_lobby',
				method: 'POST',
				body: data,
			}),
			transformResponse: (response) => response,
			invalidatesTags: ['AllResults'],
		}),
		startNextRound: builder.mutation({
			query: (data) => ({
				url: `admin/event/end_round`,
				method: 'POST',
				body: data,
			}),
			transformResponse: (response) => response,
			invalidatesTags: ['AllResults'],
		}),
		disqualifyTeam: builder.mutation({
			query: (data) => ({
				url: `admin/event/teams/disqualify`,
				method: 'POST',
				body: data,
			}),
			transformResponse: (response) => response,
			invalidatesTags: ['AllResults'],
		}),
		writeContractApi: builder.mutation({
			query: (data) => ({
				url: 'admin/predicto',
				method: 'POST',
				body: data,
			}),
			invalidatesTags: [`AllPrediction`],
		}),
		getAllPredictions: builder.query({
			query: () => ({
				url: `predicto`,
				method: 'GET',
			}),
			transformResponse: (response) => response.data,
			providesTags: [`AllPrediction`],
		}),
		updatePrediction: builder.mutation({
			query: (data) => ({
				url: 'admin/predicto',
				method: 'PUT',
				body: data,
			}),
			invalidatesTags: [`AllPrediction`],
		}),
	}),
});

export const {
	useGetCommunitiesQuery,
	useUpdateAdminCommunityMutation,
	useDeleteCommunityMutation,
	useUdpateAnnouncementsMutation,
	useDeactiveUserProfileMutation,
	useUpdatePredictionMutation,
	useWriteContractApiMutation,
	useGetProfilesQuery,
	useUpdateAdminUsersMutation,
	useAirdropTurfXpMutation,
	useGetAllPredictionsQuery,
	useAirdropReferralMutation,
	useCreateAnnouncementsMutation,
	useCreateEventMutation,
	useFetchAllEventsQuery,
	useDeleteEventsMutation,
	useUpdateEventDetailsMutation,
	useFetchAllTeamsQuery,
	useFetchAllLobbiesQuery,
	useFetchResultsQuery,
	useStartNextRoundMutation,
	useAddResultsMutation,
	useDisqualifyTeamMutation,
	useSearchCommunityQuery,
	useSearchUsersQuery,
	useFetchAnnoucementsQuery,
	useDeleteAnnoucementsMutation,
	useDeletePredictionMutation,
	useIsAdminQuery,
	useFetchAllLevelsQuery,
} = communitiesApi;
