import { createSlice } from '@reduxjs/toolkit';
import { StoreNames } from '../config';

const initialState = {
    address: localStorage.getItem('walletAddress') || '', // Initialize with persisted value
    predictionData: [],
};

const addressSlice = createSlice({
    name: StoreNames.ADDRESS,
    initialState,
    reducers: {
        setAddress(state, action) {
            state.address = action.payload;

            if (action.payload) {
                // Save to localStorage if address is provided
                localStorage.setItem('walletAddress', action.payload);
            } else {
                // Remove from localStorage if address is cleared
                localStorage.removeItem('walletAddress');
            }
        },
        setPredictionData(state, action) {
            state.predictionData = action.payload;
        },
    },
});

export const { setAddress, setPredictionData } = addressSlice.actions;

export default addressSlice.reducer;
