import { Upload } from 'lucide-react';
import React, { useState } from 'react';
import MemeCanvas from './components/MemeCanvas';
import PropsGrid from './components/PropsGrid';
import TextControl from './components/TextControl';

export default function Meme() {
	const [imageUrl, setImageUrl] = useState('');
	const [topText, setTopText] = useState('');
	const [bottomText, setBottomText] = useState('');
	const [topTextScale, setTopTextScale] = useState(1);
	const [bottomTextScale, setBottomTextScale] = useState(1);
	const [props, setProps] = useState([]);

	const handleImageUpload = (e) => {
		const file = e.target.files?.[0];
		if (file) {
			const url = URL.createObjectURL(file);
			setImageUrl(url);
		}
	};

	const handleAddProp = (prop) => {
		const newProp = {
			...prop,
			id: `${prop.id}-${Date.now()}`,
			scale: 1,
		};
		setProps([...props, newProp]);
	};

	const handlePropScale = (id, scale) => {
		setProps(props.map((prop) => (prop.id === id ? { ...prop, scale } : prop)));
	};

	const handleRemoveProp = (id) => {
		setProps(props.filter((prop) => prop.id !== id));
	};

	return (
		<div className='min-h-screen bg-gray-900 meme-container'>
			<main className='container mx-auto px-4 py-8'>
				<h1 className='text-4xl font-mono text-yellow-400 text-center mb-8'>
					$WODS Meme Generator
				</h1>

				<div className='flex gap-4 lg:flex-row flex-col-reverse'>
					<div className='space-y-6 flex-1'>
						<div className='bg-black p-4 sm:p-6 rounded-lg'>
							<h2 className='text-xl sm:text-2xl font-mono text-yellow-400 mb-4'>Upload Image</h2>

							<div className='space-y-4'>
								<div className='flex flex-col sm:flex-row items-center gap-4'>
									<label className='w-full'>
										<input
											type='text'
											value={imageUrl}
											onChange={(e) => setImageUrl(e.target.value)}
											placeholder='Enter image URL'
											className='w-full px-4 py-2 bg-gray-800 text-white rounded focus:ring-2 focus:ring-yellow-400'
										/>
									</label>
									<span className='text-white hidden sm:block'>or</span>
									<label className='flex items-center justify-center px-4 py-2 bg-blue-600 text-white rounded cursor-pointer hover:bg-blue-700 w-full sm:w-auto'>
										<Upload className='w-4 h-4 mr-2' />
										Upload
										<input
											type='file'
											accept='image/*'
											onChange={handleImageUpload}
											className='hidden'
										/>
									</label>
								</div>
							</div>
						</div>

						<div className='bg-black p-4 sm:p-6 rounded-lg'>
							<h2 className='text-xl sm:text-2xl font-mono text-yellow-400 mb-4'>Add Text</h2>
							<div className='space-y-4'>
								<TextControl
									label='Top Text'
									value={topText}
									onChange={setTopText}
									scale={topTextScale}
									onScale={setTopTextScale}
								/>
								<TextControl
									label='Bottom Text'
									value={bottomText}
									onChange={setBottomText}
									scale={bottomTextScale}
									onScale={setBottomTextScale}
								/>
							</div>
						</div>

						<div className='bg-black p-4 sm:p-6 rounded-lg'>
							<h2 className='text-xl sm:text-2xl font-mono text-yellow-400 mb-4'>Props Gallery</h2>
							<PropsGrid
								onSelectProp={handleAddProp}
								activeProps={props}
								onScaleProp={handlePropScale}
								onRemoveProp={handleRemoveProp}
							/>
						</div>
					</div>

					<div className='lg:sticky lg:top-8 flex-1'>
						<MemeCanvas
							imageUrl={imageUrl}
							topText={topText}
							bottomText={bottomText}
							topTextScale={topTextScale}
							bottomTextScale={bottomTextScale}
							props={props}
							onDeleteProp={handleRemoveProp}
							onPropScale={handlePropScale}
						/>
					</div>
				</div>
			</main>
		</div>
	);
}
