import { createSlice } from '@reduxjs/toolkit';

const initState = {
	markets: [],
	filteredMarkets: [],
	selectedMarket: null,
	loadingMarkets: true,
	loadingAddress: false,
	graphLoading: true,
	graphOutcomeNames: {},
	graphData: [],
	dataType: '24_hours',
};

const marketReducers = createSlice({
	name: 'market',
	initialState: initState,
	reducers: {
		setMarkets: (state, action) => {
			state.markets = action.payload;
			state.filteredMarkets = action.payload;
		},
		setSelectedMarket: (state, action) => {
			state.selectedMarket = state.markets.find((market) => market.address === action.payload);
		},
		setMarketLoading: (state, action) => {
			state.loadingMarkets = action.payload;
		},
		setFilteredMarkets: (state, action) => {
			state.filteredMarkets = action.payload;
		},
		setGraphData: (state, action) => {
			state.graphData = action.payload;
		},
		setGraphOutcomeNames: (state, action) => {
			state.graphOutcomeNames = action.payload;
		},
		setGraphLoading: (state, action) => {
			state.graphLoading = action.payload;
		},
		setDataType: (state, action) => {
			state.dataType = action.payload;
		},
		setLoadingAddress: (state, action) => {
			state.loadingAddress = action.payload;
		},
		setMarketData: (state, action) => {
			state.selectedMarket = action.payload;
		}
	},
});

export const {
	setMarkets,
	setSelectedMarket,
	setMarketLoading,
	setFilteredMarkets,
	setDataType,
	setGraphData,
	setGraphLoading,
	setGraphOutcomeNames,
	setMarketData,
	setLoadingAddress,
} = marketReducers.actions;

export default marketReducers.reducer;
