import { useEffect, useState } from 'react';

export const useImageDimensions = (imageUrl) => {
	const [dimensions, setDimensions] = useState(null);

	useEffect(() => {
		if (!imageUrl) {
			setDimensions(null);
			return;
		}

		const img = new Image();
		img.onload = () => {
			const naturalWidth = img.naturalWidth;
			const naturalHeight = img.naturalHeight;

			const maxWidth = 800;
			const scale = Math.min(1, maxWidth / naturalWidth);

			setDimensions({
				naturalWidth,
				naturalHeight,
				displayWidth: naturalWidth * scale,
				displayHeight: naturalHeight * scale,
			});
		};
		img.src = imageUrl;
	}, [imageUrl]);

	return dimensions;
};
