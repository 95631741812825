import { Menu, User, Wallet, X } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { connectWallet } from '../../services/api.connectWallet';
import { StoreNames } from '../../store';
import { setAddress } from '../../store/reducers/addressReducers';

export default function Header() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isExpanded, setExpanded] = useState(false);
    const [error, setError] = useState(null);
    const { address } = useSelector((state) => state[StoreNames.ADDRESS]);

    useEffect(() => {
        // Redirect to /predict if wallet is disconnected and current route is /profile
        if (!address && window.location.pathname === '/profile') {
            navigate('/predict');
        }
    }, [address, navigate]);

    const handleConnectWallet = async () => {
        try {
            const { walletAddress } = await connectWallet();
            dispatch(setAddress(walletAddress));
            setError(null);
        } catch (err) {
            setError(err.message);
        }
    };

    const handleDisconnectWallet = () => {
        // Clear wallet address from Redux store
        dispatch(setAddress(null));

        // Clear wallet address from localStorage
        localStorage.removeItem('walletAddress');
    };

    const formatWalletAddress = (address) => {
        if (!address) return '';
        return `${address.slice(0, 6)}...${address.slice(-4)}`;
    };

    return (
        <>
            <nav className="bg-black border-b border-yellow-500/20">
                <div className="container mx-auto p-4">
                    <div className="flex items-center justify-between">
                        <Link className="flex items-center space-x-4" to={'/'}>
                            <div className="flex flex-col">
                                <div className="text-yellow-500 font-bold text-xl">
                                    THE DUMB STREET
                                </div>
                                <span className="subtext text-sm">In Beta</span>
                            </div>
                        </Link>
                        <div className="items-center gap-x-12 hidden lg:flex">
                            <Link
                                to="/predict"
                                className={`cursor-pointer ${
                                    window.location.pathname === '/predict' &&
                                    'text-yellow-400'
                                }`}
                            >
                                Predictions
                            </Link>
                            <Link
                                to="/create-prediction"
                                className={`cursor-pointer ${
                                    window.location.pathname ===
                                        '/create-prediction' &&
                                    'text-yellow-400'
                                }`}
                            >
                                Create Predictions
                            </Link>
                            <Link
                                to="/meme"
                                className={`cursor-pointer ${
                                    window.location.pathname === '/meme' &&
                                    'text-yellow-400'
                                }`}
                            >
                                Meme
                            </Link>
                        </div>

                        <div className="flex items-center space-x-4">
                            {address ? (
                                <div className="flex items-center space-x-2 bg-yellow-500 px-3 py-2 rounded-md">
                                    <span className="text-black font-semibold">
                                        {formatWalletAddress(address)}
                                    </span>
                                    <button
                                        onClick={handleDisconnectWallet}
                                        className="text-black hover:text-red-600"
                                    >
                                        <X className="w-4 h-4" />
                                    </button>
                                </div>
                            ) : (
                                <button
                                    onClick={handleConnectWallet}
                                    className="hidden lg:flex items-center px-4 py-2 bg-yellow-500 text-black font-semibold rounded-md hover:bg-yellow-400 transition-colors"
                                >
                                    <Wallet className="w-4 h-4 mr-2" />
                                    Connect Wallet
                                </button>
                            )}
                            <button
                                className="lg:hidden"
                                onClick={() => setExpanded(!isExpanded)}
                            >
                                <Menu className="w-6 h-6 text-yellow-500" />
                            </button>
                            {address && (
                                <button
                                    onClick={() => navigate('profile')}
                                    className=""
                                >
                                    <User className="text-yellow-500" />
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </nav>

            {isExpanded && (
                <div className="bg-black text-white text-center py-4 lg:hidden block">
                    <Link to="/predict" className="block py-2">
                        Predictions
                    </Link>
                    <Link to="/create-prediction" className="block py-2">
                        Create Predictions
                    </Link>
                    <Link to="/meme" className="block py-2">
                        Meme
                    </Link>
                    {address ? (
                        <div className="mx-auto flex items-center space-x-2 bg-yellow-500 px-3 py-2 rounded-md">
                            <span className="text-black font-semibold">
                                {formatWalletAddress(address)}
                            </span>
                            <button
                                onClick={handleDisconnectWallet}
                                className="text-black hover:text-red-600"
                            >
                                <X className="w-4 h-4" />
                            </button>
                        </div>
                    ) : (
                        <button
                            onClick={handleConnectWallet}
                            className="mx-auto lg:hidden flex items-center px-4 py-2 bg-yellow-500 text-black font-semibold rounded-md hover:bg-yellow-400 transition-colors"
                        >
                            <Wallet className="w-4 h-4 mr-2" />
                            Connect Wallet
                        </button>
                    )}
                </div>
            )}

            {error && (
                <div className="text-red-500 text-center mt-2">{error}</div>
            )}
        </>
    );
}
