import { ScaleIcon } from 'lucide-react';
import React from 'react';

export const ScaleControl = ({ scale, onChange, min = 0.5, max = 2 }) => {
	const handleChange = (e) => {
		const newScale = parseFloat(e.target.value);
		onChange(newScale);
	};

	return (
		<div className='flex items-center gap-2 min-w-[150px]'>
			<ScaleIcon className='w-4 h-4 text-yellow-400 flex-shrink-0' />
			<input
				type='range'
				min={min}
				max={max}
				step='0.1'
				value={scale}
				onChange={handleChange}
				className='w-full h-1 bg-gray-700 rounded-lg appearance-none cursor-pointer range-slider'
			/>
			<span className='text-xs text-white font-mono flex-shrink-0'>{scale.toFixed(1)}x</span>
		</div>
	);
};
