import { ethers, formatUnits } from 'ethers';
import { PREDICTO_ABI } from '../config/abi/predicto.abi'; // Update with correct path
import { FETCH_TRANSACTION_RPC } from '../config/config.wods'; // Update with correct path

const provider = new ethers.JsonRpcProvider(FETCH_TRANSACTION_RPC);

export const fetchPredictionByContractId = async (contractId) => {
	try {
		const predictionInstance = new ethers.Contract(contractId, PREDICTO_ABI, provider);

		// Fetch the required data from the contract
		const [marketResponse, outcomes1, outcomes2] = await Promise.all([
			predictionInstance.market(),
			predictionInstance.outcomes(0),
			predictionInstance.outcomes(1),
		]);

		const minPrice = Number(formatUnits(marketResponse.minPrice, 18));
		const maxPrice = Number(formatUnits(marketResponse.maxPrice, 18));

		const finalOutcome = marketResponse[13] ? parseInt(marketResponse[12]) : null;

		const finalVal = finalOutcome !== null ? await predictionInstance.outcomes(finalOutcome) : null;

		const participate =
			finalOutcome !== null ? await predictionInstance.getParticipants(finalOutcome) : null;

		const marketDetail = JSON.parse(marketResponse[2]);

		// Format the data into a clean response object
		return {
			admin: marketResponse[0],
			title: marketDetail.name,
			description: marketDetail.description,
			image: marketDetail['image-hash'],
			category: marketDetail.category,
			options1: outcomes1,
			options2: outcomes2,
			minPrice,
			maxPrice,
			date: new Date(parseInt(marketResponse[10]) * 1000),
			total_bets: parseInt(marketResponse[8]),
			total_rewards: parseInt(marketResponse[7]) / 1e18,
			address: contractId,
			finalOutcome: finalVal ? finalVal[0] : null,
			participate: participate ? participate : [],
			outcomePercentage1: (parseInt(outcomes1[2]) / parseInt(marketResponse[8])) * 100,
			outcomePercentage2: (parseInt(outcomes2[2]) / parseInt(marketResponse[8])) * 100,
		};
	} catch (err) {
		console.error(`Error fetching prediction for contract ${contractId}:`, err);
		throw new Error('Failed to fetch prediction data');
	}
};
