import { AlertCircle, DollarSign } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { handlePrediction } from '../../../../services/api.participate';

export function VotingCard({ data, selectedIndex, setSelectedIndex }) {
	const [amount, setAmount] = useState(data.minPrice); // Default to minPrice
	const [sliderValue, setSliderValue] = useState(data.minPrice); // Default to minPrice
	const [loading, setLoading] = useState(false);
	const [link, setLink] = useState('');

	const win = selectedIndex === 0 ? data.options1[1] : data.options2[1];
	const f = parseInt(win) / 1e18;
	const final = (amount / (f + amount)) * (data.total_rewards + amount);

	const { minPrice, maxPrice } = data;

	const handleAmountChange = (value) => {
		const numericValue = parseFloat(value);
		setAmount(numericValue);
		setSliderValue(numericValue);
	};

	const handleSliderChange = (value) => {
		const numericValue = parseFloat(value);
		setSliderValue(numericValue);
		setAmount(numericValue);
	};

	useEffect(() => {
		setAmount(data.minPrice)
		setSliderValue(data.minPrice)
	}, [data.minPrice])

	const handleParticipateUser = async () => {
		if (!amount) return toast.error('Please enter an amount');
		if (loading || link) return;
		if (amount < minPrice || amount > maxPrice) {
			toast.error(`Amount must be between ${minPrice} and ${maxPrice}`);
			return;
		}

		try {
			setLoading(true);
			const d = await handlePrediction({
				addr: data.address,
				amount: amount,
				index: selectedIndex,
			});
			if (d) {
				setLink(d);
				toast.success('Participation successful 🚀');
			} else {
				toast.error('You cannot participate more than once.');
			}
		} catch (err) {
			toast.error('Something went wrong');
		} finally {
			setLoading(false);
		}
	};

	// Function to determine step value based on min and max prices
	const getStepValue = (min, max) => {
		// Convert numbers to strings to check their decimal places
		const minStr = min.toString();
		const maxStr = max.toString();

		// Get decimal places length (if any)
		const minDecimals = minStr.includes('.') ? minStr.split('.')[1].length : 0;
		const maxDecimals = maxStr.includes('.') ? maxStr.split('.')[1].length : 0;

		// Use the larger number of decimal places
		const decimals = Math.max(minDecimals, maxDecimals);

		// If no decimals, return 1, otherwise return appropriate decimal step
		return decimals === 0 ? '1' : `${1 / Math.pow(10, decimals)}`;
	};

	return (
		<div className='bg-[#0D0D0D] border border-yellow-500/20 rounded-lg min-w-lg max-w-lg flex flex-col  overflow-y-auto'>
			<div className='p-4 space-y-4 flex-grow overflow-y-auto '>
				<img src={data.image} alt='' className='w-full object-cover rounded-lg aspect-video' />
				{/* {isWinner ? ( */}
				{/* <button
						disabled={link || loading}
						onClick={handleWithdraw}
						className='disabled:cursor-not-allowed w-full px-6 py-3 bg-yellow-500 text-black font-semibold rounded-lg hover:bg-yellow-400 transition-colors'
					>
						{link ? (
							<a
								href={`https://basescan.org/tx/${link}`}
								target='_blank'
								rel='noreferrer'
								className='flex items-center gap-x-2 justify-center'
							>
								View on explorer
								<svg
									xmlns='http://www.w3.org/2000/svg'
									fill='none'
									viewBox='0 0 24 24'
									strokeWidth={1.5}
									stroke='currentColor'
									className='size-5'
								>
									<path
										strokeLinecap='round'
										strokeLinejoin='round'
										d='M13.19 8.688a4.5 4.5 0 0 1 1.242 7.244l-4.5 4.5a4.5 4.5 0 0 1-6.364-6.364l1.757-1.757m13.35-.622 1.757-1.757a4.5 4.5 0 0 0-6.364-6.364l-4.5 4.5a4.5 4.5 0 0 0 1.242 7.244'
									/>
								</svg>
							</a>
						) : loading ? (
							<div className='flex items-center gap-x-3 justify-center'>
								Please wait
								<div role='status'>
									<svg
										aria-hidden='true'
										className='size-5 text-gray-200 animate-spin fill-black'
										viewBox='0 0 100 101'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
									>
										<path
											d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
											fill='currentColor'
										/>
										<path
											d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
											fill='currentFill'
										/>
									</svg>
									<span className='sr-only'>Loading...</span>
								</div>
							</div>
						) : (
							'Withdraw'
						)}
					</button>
				) :  */}
				{data.finalOutcome ? (
					<div className='pt-4 flex flex-col gap-2'>
						<div>
							<div className='flex items-center text-sm text-gray-400'>
								<AlertCircle className='w-4 h-4 mr-2' />
								Voting ends in {new Date(data.date).toLocaleString()}
							</div>
						</div>
						<div className='flex items-center justify-between text-sm'>
							<span className='text-gray-400'>Volume</span>
							<span className='flex items-center'>
								<DollarSign className='w-4 h-4' />
								WODS {data.total_rewards}
							</span>
						</div>
						<div className='w-full h-2 bg-red-700 rounded-full overflow-hidden'>
							<div
								className='h-full bg-green-500'
								style={{
									width: `${data?.outcomePercentage1 ? data.outcomePercentage1 : 0}%`,
								}}
							/>
						</div>
						<div className='flex items-center justify-between text-sm'>
							<span>
								{data.options1[0]}{' '}
								{data?.outcomePercentage1 ? data.outcomePercentage1.toFixed(2) : 0}%
							</span>
							<span>
								{data.options2[0]}{' '}
								{data?.outcomePercentage2 ? data.outcomePercentage2.toFixed(2) : 0}%
							</span>
						</div>
						<div className='inline-flex gap-2'>
							{'Outcome is ' + data.finalOutcome}{' '}
							<svg
								xmlns='http://www.w3.org/2000/svg'
								fill='none'
								viewBox='0 0 24 24'
								strokeWidth={1.5}
								stroke='#EAB308'
								className='size-6'
							>
								<path
									strokeLinecap='round'
									strokeLinejoin='round'
									d='M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z'
								/>
							</svg>
						</div>
					</div>
				) : (
					<>
						<div>Select an option:</div>
						<div className='grid grid-cols-2 gap-2'>
							{/* YES Button */}
							<button
								onClick={() => {
									if (new Date() > new Date(data.date)) {
										toast.error('Campaign has ended');
										return;
									}
									setSelectedIndex(0);
								}}
								className={`px-4 py-2  text-green-400 ${
									selectedIndex === 0 ? 'bg-green-500/60' : 'bg-green-500/20'
								} rounded-md hover:bg-green-500/40 transition-colors`}
							>
								{/* {data.options1.map((option, index) => (
									<div key={index}>{option}</div>
								))} */}
								{data.options1[0]}
							</button>

							{/* NO Button */}
							<button
								onClick={() => {
									if (new Date() > new Date(data.date)) {
										toast.error('Campaign has ended');
										return;
									}
									setSelectedIndex(1);
								}}
								className={`px-4 py-2  ${
									selectedIndex === 1 ? 'bg-red-500/50' : 'bg-red-500/20'
								} text-red-400 rounded-md hover:bg-red-500/60 transition-colors`}
							>
								{data.options2[0]}
							</button>
						</div>
						<div>
							<div className='flex items-center text-sm text-gray-400'>
								<AlertCircle className='w-4 h-4 mr-2' />
								Voting ends in {new Date(data.date).toLocaleString()}
							</div>
						</div>
						{selectedIndex !== null && (
							<div className='space-y-4'>
								<div>
									<label className='block text-sm font-medium text-gray-400 mb-2'>
										Select Amount (WODS)
									</label>
									<input
										type='range'
										min={minPrice}
										max={maxPrice}
										step={getStepValue(minPrice, maxPrice)} // Adjust the step for floating values
										value={sliderValue || minPrice}
										onChange={(e) => handleSliderChange(e.target.value)}
										className='w-full h-2 rounded-lg appearance-none disabled:cursor-not-allowed bg-black '
										style={{
											background: `linear-gradient(to right, #facc15 ${
												((sliderValue - minPrice) / (maxPrice - minPrice)) * 100
											}%, #e5e7eb3b ${((sliderValue - minPrice) / (maxPrice - minPrice)) * 100}%)`,
										}}
									/>
									<div className='flex justify-between text-xs text-gray-500 mt-1'>
										<span>{minPrice.toFixed(2)} WODS</span>
										<span>{maxPrice.toFixed(2)} WODS</span>
									</div>
								</div>

								<div>
									<label className='block text-sm font-medium text-gray-400 mb-2'>
										Amount (WODS)
									</label>
									<input
										type='number'
										step='0.01'
										value={amount}
										onChange={(e) => {
											if (Number(e.target.value) < minPrice || Number(e.target.value) > maxPrice) {
												return;
											}
											handleAmountChange(e.target.value);
										}}
										className='w-full bg-black border border-yellow-500/20 rounded-lg px-4 py-2 focus:outline-none focus:border-yellow-500 disabled:cursor-not-allowed disabled:bg-gray-800 disabled:text-gray-500'
										placeholder={`Enter amount (${minPrice.toFixed(2)}-${maxPrice.toFixed(2)})...`}
										min={minPrice}
										max={maxPrice}
									/>
								</div>

								<div className='bg-yellow-500/10 rounded-lg p-4'>
									<div className='flex justify-between text-sm mb-2'>
										<span>Voting for</span>
										<span className='text-yellow-500 font-semibold'>
											{selectedIndex === 0
												? data.options1.map((option, index) => <div key={index}>{option}</div>)
												: selectedIndex === 1
												? data.options2.map((option, index) => <div key={index}>{option}</div>)
												: null}
										</span>
									</div>
									<div className='flex justify-between'>
										<span>Potential win</span>
										<span className='text-yellow-500 font-semibold'>
											{final ? final.toFixed(2) : '-'} WODS
										</span>
									</div>
								</div>
							</div>
						)}

						<div className=' flex space-x-4'>
							<button
								disabled={selectedIndex === null || loading}
								onClick={handleParticipateUser}
								className='w-full px-6 py-3 bg-yellow-500 text-black font-semibold rounded-lg hover:bg-yellow-400 transition-colors disabled:cursor-not-allowed disabled:bg-gray-800 disabled:text-gray-500'
							>
								{loading ? 'Please wait...' : link ? 'View on explorer' : 'Confirm Prediction'}
							</button>
						</div>
					</>
				)}
			</div>
		</div>
	);
}
