import { AlertCircle, X } from 'lucide-react';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { FaDollarSign } from 'react-icons/fa6';
import { FiExternalLink } from 'react-icons/fi';
import CONFIG from '../../../../config/config.wods';
import { handlePrediction } from '../../../../services/api.participate';

export function VotingModal({ data, onClose, selectedIndex, imageURL }) {
	const [amount, setAmount] = useState(data.minPrice); // Default to minPrice
	const [sliderValue, setSliderValue] = useState(data.minPrice); // Default to minPrice
	const [showFullDescription, setShowFullDescription] = useState(false);
	const [loading, setLoading] = useState(false);
	const [link, setLink] = useState('');

	const win = selectedIndex === 0 ? data.options1[1] : data.options2[1];
	const f = parseInt(win) / 1e18;
	const final = (amount / (f + amount)) * (data.total_rewards + amount);

	const { minPrice, maxPrice } = data;

	const handleAmountChange = (value) => {
		const numericValue = parseFloat(value);
		setAmount(numericValue);
		setSliderValue(numericValue);
	};

	const handleSliderChange = (value) => {
		const numericValue = parseFloat(value);
		setSliderValue(numericValue);
		setAmount(numericValue);
	};

	const handleParticipateUser = async () => {
		if (!amount) return toast.error('Please enter an amount');
		if (loading || link) return;
		if (amount < minPrice || amount > maxPrice) {
			toast.error(`Amount must be between ${minPrice} and ${maxPrice}`);
			return;
		}

		try {
			setLoading(true);
			const d = await handlePrediction({
				addr: data.address,
				amount: amount,
				index: selectedIndex,
			});
			if (d) {
				setLink(d);
				toast.success('Participation successful 🚀');
			} else {
				toast.error('You cannot participate more than once.');
			}
		} catch (err) {
			toast.error('Something went wrong');
		} finally {
			setLoading(false);
		}
	};

	let title = data.title;
	let description = data.title;

	if (data.title.includes('{')) {
		const resolvedData = JSON.parse(data.title);
		title = resolvedData.name;
		description = resolvedData.description;
	}

	let descriptionJSX = <p className='text-sm text-gray-300 leading-relaxed'>{description}</p>;
	if (description.length > 50) {
		descriptionJSX = (
			<p className='text-sm text-gray-300 leading-relaxed'>
				{showFullDescription ? description : `${description.split(' ').slice(0, 10).join(' ')}...`}
				<button
					onClick={() => setShowFullDescription(!showFullDescription)}
					className='ml-2 text-yellow-500 font-medium underline hover:text-yellow-400'
				>
					{showFullDescription ? 'show less' : 'show more'}
				</button>
			</p>
		);
	}

	// Function to determine step value based on min and max prices
	const getStepValue = (min, max) => {
		// Convert numbers to strings to check their decimal places
		const minStr = min.toString();
		const maxStr = max.toString();

		// Get decimal places length (if any)
		const minDecimals = minStr.includes('.') ? minStr.split('.')[1].length : 0;
		const maxDecimals = maxStr.includes('.') ? maxStr.split('.')[1].length : 0;

		// Use the larger number of decimal places
		const decimals = Math.max(minDecimals, maxDecimals);

		// If no decimals, return 1, otherwise return appropriate decimal step
		return decimals === 0 ? '1' : `${1 / Math.pow(10, decimals)}`;
	};

	return (
		<div className='fixed inset-0 bg-black/80 flex items-center justify-center p-4 z-50'>
			<div className='bg-[#0D0D0D] border border-yellow-500/20 rounded-lg w-full max-w-lg flex flex-col h-[90vh] overflow-y-auto'>
				<div className='flex items-center justify-between p-4 border-b border-yellow-500/20'>
					<h3 className='text-lg font-bold'>Place Your Prediction</h3>
					<button onClick={onClose} className='text-gray-400 hover:text-white'>
						<X className='w-6 h-6' />
					</button>
				</div>

				<div className='p-6 space-y-6 flex-grow overflow-y-auto'>
					<img src={imageURL} alt='' className='w-full h-48 object-cover rounded-lg' />

					<div>
						<h4 className='font-semibold mb-2'>{title}</h4>
						{descriptionJSX}
						<div className='flex items-center text-sm text-gray-400'>
							<AlertCircle className='w-4 h-4 mr-2' />
							Voting ends in {new Date(data.date).toLocaleString()}
						</div>
					</div>

					<div className='space-y-4'>
						<div>
							<label className='block text-sm font-medium text-gray-400 mb-2'>
								Select Amount (WODS)
							</label>
							<input
								type='range'
								min={minPrice}
								max={maxPrice}
								step={getStepValue(minPrice, maxPrice)} // Adjust the step for floating values
								value={sliderValue || minPrice}
								onChange={(e) => handleSliderChange(e.target.value)}
								className='w-full h-2 rounded-lg appearance-none cursor-pointer'
								style={{
									background: `linear-gradient(to right, #facc15 ${
										((sliderValue - minPrice) / (maxPrice - minPrice)) * 100
									}%, #e5e7eb3b ${((sliderValue - minPrice) / (maxPrice - minPrice)) * 100}%)`,
								}}
							/>
							<div className='flex justify-between text-xs text-gray-500 mt-1'>
								<span>{minPrice.toFixed(2)} WODS</span>
								<span>{maxPrice.toFixed(2)} WODS</span>
							</div>
						</div>

						<div>
							<label className='block text-sm font-medium text-gray-400 mb-2'>Amount (WODS)</label>
							<input
								type='number'
								step='0.01'
								value={amount}
								onChange={(e) => handleAmountChange(e.target.value)}
								className='w-full bg-black border border-yellow-500/20 rounded-lg px-4 py-2 focus:outline-none focus:border-yellow-500'
								placeholder={`Enter amount (${minPrice.toFixed(2)}-${maxPrice.toFixed(2)})...`}
							/>
						</div>

						<div className='bg-yellow-500/10 rounded-lg p-4'>
							<div className='flex justify-between text-sm mb-2'>
								<span>Voting for</span>
								<span className={selectedIndex === 0 ? 'text-green-400' : 'text-red-400'}>
									{selectedIndex === 0 ? 'Yes' : 'No'}
								</span>
							</div>
							<div className='flex justify-between'>
								<span>Potential win</span>
								<span className='text-yellow-500 font-semibold'>
									{final ? final.toFixed(2) : '-'} WODS
								</span>
							</div>
						</div>
					</div>
				</div>
				<div className='p-4 border-t border-yellow-500/20 flex space-x-4'>
					<button
						onClick={handleParticipateUser}
						className='w-1/2 px-6 py-3 bg-yellow-500 text-black font-semibold rounded-lg hover:bg-yellow-400 transition-colors'
					>
						{loading ? 'Please wait...' : link ? 'View on explorer' : 'Confirm Prediction'}
					</button>
					<button
						className='hidden md:flex items-center px-4 py-3 bg-yellow-500/15 rounded-lg w-1/2 text-center justify-center space-x-2 align-middle hover:underline'
						onClick={() => window.open(CONFIG.BUY_WODS_LINK, '_blank')}
					>
						Buy
						<span className='flex items-center'>
							<FaDollarSign />
							WODS
						</span>
						<FiExternalLink />
					</button>
				</div>
			</div>
		</div>
	);
}
