import md5 from 'blueimp-md5';
import html2canvas from 'html2canvas';
import { PINATA_GATEWAY } from './config.wods';

export const takeScreenshot = async (element, options = {}) => {
	if (!element) return;

	const {
		hideElements = ['.controls-panel', '.settings-button', '.scale-control'],
		quality = 1.0,
	} = options;

	const elementsToHide = Array.from(element.querySelectorAll(hideElements.join(', ')));

	const originalVisibility = elementsToHide.map((el) => el.style.display);

	try {
		elementsToHide.forEach((el) => {
			el.style.display = 'none';
		});

		const canvas = await html2canvas(element, {
			useCORS: true,
			allowTaint: true,
			backgroundColor: null,
			scale: window.devicePixelRatio,
			logging: false,
			onclone: (clonedDoc) => {
				const clonedElements = clonedDoc.querySelectorAll(hideElements.join(', '));
				clonedElements.forEach((el) => {
					el.style.display = 'none';
				});
			},
		});

		const link = document.createElement('a');
		link.download = 'wods-meme.png';
		link.href = canvas.toDataURL('image/png', quality);
		link.click();
	} finally {
		elementsToHide.forEach((el, index) => {
			el.style.display = originalVisibility[index];
		});
	}
};

export const generateImageUrl = (category, id, imageHash) => {
	if (imageHash) {
		return `https://${PINATA_GATEWAY}/ipfs/${imageHash}`;
	}
	const hash = md5(`${category.toLowerCase().trim()}${id}`);
	return `https://www.gravatar.com/avatar/${hash}?d=identicon&s=200`;
};
