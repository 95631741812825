// Configuration object
const config = {
    FETCH_TRANSACTION_RPC: 'https://base-rpc.publicnode.com',
    PINATA_JWT:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySW5mb3JtYXRpb24iOnsiaWQiOiI3YzcxNmRmZS1jMDVkLTQ3YTEtYjNiYS04MDI3MWVmYzdjMWMiLCJlbWFpbCI6ImJ1eXdvZHNAeWFob28uY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsInBpbl9wb2xpY3kiOnsicmVnaW9ucyI6W3siZGVzaXJlZFJlcGxpY2F0aW9uQ291bnQiOjEsImlkIjoiRlJBMSJ9LHsiZGVzaXJlZFJlcGxpY2F0aW9uQ291bnQiOjEsImlkIjoiTllDMSJ9XSwidmVyc2lvbiI6MX0sIm1mYV9lbmFibGVkIjpmYWxzZSwic3RhdHVzIjoiQUNUSVZFIn0sImF1dGhlbnRpY2F0aW9uVHlwZSI6InNjb3BlZEtleSIsInNjb3BlZEtleUtleSI6ImIyODNhNmEyMDZhYTBjOTdiYTY0Iiwic2NvcGVkS2V5U2VjcmV0IjoiYmYzYTdmY2M2ZGVlOTc1NzIxNGYwNzM0MzNkNDc4ZTE5ZDUxNjhiMzkwMjk0NDRmNGFkMWE2NGUyODZjODU1ZCIsImV4cCI6MTc2NTM2OTk1N30.4CO16K4RnYL16wo2M1ujWANSNq0ACp_64uFE8Ze9q8I',
    PINATA_GATEWAY: 'crimson-actual-gopher-85.mypinata.cloud',
    DEXSCREENER_URI:
        'https://dexscreener.com/base/0x6407d112bf20ea15f98a25d4714961bd6d6d0377',
    PROXY_URL_HEROKU: 'https://cors-anywhere.herokuapp.com/',
    PRICE_API_URL: 'https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v3',
    POOL_ID: '0x6407D112Bf20ea15f98a25D4714961bd6D6D0377',
    SOCIAL_LINKS: {
        X: 'https://x.com/wodsfun',
        TG: 'https://t.me/wodschat',
        FARCASTER: 'https://warpcast.com/~/channel/wodsfun',
        DEXSCREENER: 'https://dexscreener.com/base/0x6407d112bf20ea15f98a25d4714961bd6d6d0377?maker=0x89D242b0335Ceb5CFe41f8522948A805F1b0c98C',
        DEXTOOLS: 'https://www.dextools.io/app/en/token/wodsfun?t=1736301948004',
        UNISWAP: 'https://app.uniswap.org/explore/tokens/base/0x4398c398e5ac747e6d51bf1db1dac346ca90fee0'
    },
    DOC: 'https://wodsfun.hashnode.space',
    BUY_WODS_LINK:
        'https://app.uniswap.org/explore/tokens/base/0x4398c398e5ac747e6d51bf1db1dac346ca90fee0',
    CURR_RPC_URL: '',
    CHAIN_CONFIG: {
        chainId: '0x2105', // Hexadecimal for Base chain ID 8453
        chainName: 'Base Mainnet',
        rpcUrls: ['https://base-rpc.publicnode.com'],
        nativeCurrency: {
            name: 'ETH',
            symbol: 'ETH',
            decimals: 18,
        },
        blockExplorerUrls: ['https://basescan.org/'],
    },
    FACTORY_CONTRACT_ADDRESS: '0x60754403f8fdD9959540dbf8eaF7E706EEd7CCF7',
    WOODS_TOKEN: '0x4398c398e5ac747e6d51bf1db1dac346ca90fee0',
    CA_ADDRESS: '0x4398c398e5ac747e6d51bf1db1dac346ca90fee0',
    BASESCAN_API_KEY: 'ZKHFT75WT412FS2U1H9BNCPUHD4BPNV4IH',
    BASESCAN_API: 'https://api.basescan.org/api',
};

// Export default and named exports
export default config;
export const {
    FETCH_TRANSACTION_RPC,
    DEXSCREENER_URI,
    DOC,
    PROXY_URL_HEROKU,
    PRICE_API_URL,
    POOL_ID,
    SOCIAL_LINKS,
    BUY_WODS_LINK,
    CURR_RPC_URL,
    CHAIN_CONFIG,
    FACTORY_CONTRACT_ADDRESS,
    WOODS_TOKEN,
    BASESCAN_API_KEY,
    BASESCAN_API,
    PINATA_JWT,
    PINATA_GATEWAY,
} = config;
