import { ethers } from 'ethers';
import { PREDICTO_ABI } from '../config/abi/predicto.abi';
import { FETCH_TRANSACTION_RPC } from '../config/config.wods';

export const fetchGraphDataForMarket = async (marketAddress, data_type) => {
    const provider = new ethers.JsonRpcProvider(FETCH_TRANSACTION_RPC);

    try {
        // Initialize the contract instance for the specific market
        const predictionInstance = new ethers.Contract(
            marketAddress,
            PREDICTO_ABI,
            provider
        );

        // Fetch data from the contract
        const response = await predictionInstance.market();
        const outcomes1 = await predictionInstance.outcomes(0);
        const outcomes2 = await predictionInstance.outcomes(1);

        // Extract the names of the outcomes
        const outcomeName1 = outcomes1[0]; // Assuming outcome name is at index 1
        const outcomeName2 = outcomes2[0]; // Assuming outcome name is at index 1

        // Calculate percentages
        const totalBets = parseInt(response[8]);
        const outcomePercentage1 = (parseInt(outcomes1[2]) / totalBets) * 100;
        const outcomePercentage2 = (parseInt(outcomes2[2]) / totalBets) * 100;

        // Convert timestamp to a base date
        const baseDate = new Date(parseInt(response[10]) * 1000);

        // Determine the type of data to generate
        let graphData = [];
        if (data_type === '24_hours') {
            // Generate 8 data points for the 24-hour period (every 3 hours)
            graphData = Array.from({ length: 8 }, (_, index) => {
                const date = new Date(
                    baseDate.getTime() - index * 3 * 60 * 60 * 1000 // Subtract 3 hours for each point
                ).toLocaleTimeString('en-US', {
                    hour: '2-digit',
                    minute: '2-digit',
                });

                // Simulate slight variation in percentages for each hour
                const simulatedOutcome1 =
                    outcomePercentage1 + Math.random() * 2 - 1; // +/- 1%
                const simulatedOutcome2 =
                    outcomePercentage2 + Math.random() * 2 - 1; // +/- 1%

                return {
                    date,
                    'Outcome 1': Math.max(
                        0,
                        Math.min(100, simulatedOutcome1.toFixed(2))
                    ), // Ensure percentage stays between 0-100
                    'Outcome 2': Math.max(
                        0,
                        Math.min(100, simulatedOutcome2.toFixed(2))
                    ), // Ensure percentage stays between 0-100
                };
            });
        } else if (data_type === '7_days') {
            // Generate 7 daily data points at 00:00 hours
            graphData = Array.from({ length: 7 }, (_, index) => {
                const date = new Date(
                    baseDate.getTime() - index * 24 * 60 * 60 * 1000 // Subtract 1 day for each point
                ).toLocaleDateString('en-US', {
                    weekday: 'short', // Show short weekday name
                    month: 'short', // Show short month name
                    day: '2-digit', // Show day of the month
                });

                // Simulate slight variation in percentages for each day
                const simulatedOutcome1 =
                    outcomePercentage1 + Math.random() * 5 - 2.5; // +/- 2.5%
                const simulatedOutcome2 =
                    outcomePercentage2 + Math.random() * 5 - 2.5; // +/- 2.5%

                return {
                    date,
                    'Outcome 1': Math.max(
                        0,
                        Math.min(100, simulatedOutcome1.toFixed(2))
                    ), // Ensure percentage stays between 0-100
                    'Outcome 2': Math.max(
                        0,
                        Math.min(100, simulatedOutcome2.toFixed(2))
                    ), // Ensure percentage stays between 0-100
                };
            });
        } else {
            throw new Error(
                "Invalid data_type. Please use '24_hours' or '7_days'."
            );
        }

        return {
            graphData: graphData.reverse(), // Reverse the array to show oldest data first
            outcomeName1: [outcomeName1],
            outcomeName2: [outcomeName2],
        };
    } catch (err) {
        console.error(
            `Error fetching data for market address ${marketAddress}:`,
            err
        );
        return []; // Return an empty array if there's an error
    }
};
