import React from 'react';
import { ScaleControl } from './ScaleControl';

const props = [
	{
		id: 'laser-eyes',
		type: 'image',
		url: 'https://nlsdrxedpnhnuryftrxd.supabase.co/storage/v1/object/public/assets/props/laser-eyes.png',
		name: 'Laser Eyes',
	},
	{
		id: 'wods',
		type: 'text',
		text: '$WODS',
		textColor: 'text-yellow-400',
		name: 'WODS Text',
	},
	{
		id: 'based',
		type: 'text',
		text: 'BASED',
		textColor: 'text-blue-500',
		name: 'Based Text',
	},
	{
		id: '1',
		type: 'image',
		url: 'https://nlsdrxedpnhnuryftrxd.supabase.co/storage/v1/object/public/assets/props/1.png',
		name: 'Prop 1',
	},
	{
		id: '2',
		type: 'image',
		url: 'https://nlsdrxedpnhnuryftrxd.supabase.co/storage/v1/object/public/assets/props/2.png',
		name: 'Prop 2',
	},
	{
		id: '3',
		type: 'image',
		url: 'https://nlsdrxedpnhnuryftrxd.supabase.co/storage/v1/object/public/assets/props/3.png',
		name: 'Prop 3',
	},
	{
		id: '4',
		type: 'image',
		url: 'https://nlsdrxedpnhnuryftrxd.supabase.co/storage/v1/object/public/assets/props/4.png',
		name: 'Prop 4',
	},
	{
		id: '5',
		type: 'image',
		url: 'https://nlsdrxedpnhnuryftrxd.supabase.co/storage/v1/object/public/assets/props/5.png',
		name: 'Prop 5',
	},
	{
		id: '6',
		type: 'image',
		url: 'https://nlsdrxedpnhnuryftrxd.supabase.co/storage/v1/object/public/assets/props/6.png',
		name: 'Prop 6',
	},
	{
		id: '7',
		type: 'image',
		url: 'https://nlsdrxedpnhnuryftrxd.supabase.co/storage/v1/object/public/assets/props/7.png',
		name: 'Prop 7',
	},
	{
		id: '8',
		type: 'image',
		url: 'https://nlsdrxedpnhnuryftrxd.supabase.co/storage/v1/object/public/assets/props/8.png',
		name: 'Prop 8',
	},
];

const PropsGrid = ({ onSelectProp, activeProps, onScaleProp, onRemoveProp }) => {
	return (
		<div className='space-y-6'>
			<div className='grid grid-cols-2 sm:grid-cols-4 gap-4'>
				{props.map((prop) => (
					<button
						key={prop.id}
						onClick={() => onSelectProp(prop)}
						className='bg-gray-800 rounded-lg p-2 hover:bg-gray-700 transition-colors'
					>
						{prop.type === 'image' ? (
							<img
								src={prop.url}
								alt={prop.name}
								className='w-full aspect-square object-contain'
								crossOrigin='anonymous'
							/>
						) : (
							<div
								className={`aspect-square flex items-center justify-center text-2xl font-bold ${
									prop.textColor || 'text-white'
								}`}
							>
								{prop.text}
							</div>
						)}
					</button>
				))}
			</div>

			{activeProps.length > 0 && (
				<div className='mt-6'>
					<h3 className='text-white text-lg mb-4'>Active Props</h3>
					<div className='space-y-4'>
						{activeProps.map((prop) => (
							<div key={prop.id} className='bg-gray-800 p-3 rounded-lg flex items-center gap-4'>
								<div className='w-12 h-12 flex-shrink-0'>
									{prop.type === 'image' ? (
										<img
											src={prop.url}
											alt={prop.name}
											className='w-full h-full object-contain'
											crossOrigin='anonymous'
										/>
									) : (
										<div
											className={`w-full h-full flex items-center justify-center text-xl font-bold ${
												prop.textColor || 'text-white'
											}`}
										>
											{prop.text}
										</div>
									)}
								</div>
								<div className='flex-grow'>
									<ScaleControl
										scale={prop.scale}
										onChange={(scale) => onScaleProp(prop.id, scale)}
										min={0.5}
										max={4}
									/>
								</div>
								<button
									onClick={() => onRemoveProp(prop.id)}
									className='px-2 py-1 bg-red-500 text-white text-sm rounded hover:bg-red-600 transition-colors'
								>
									Remove
								</button>
							</div>
						))}
					</div>
				</div>
			)}
		</div>
	);
};

export default PropsGrid;
