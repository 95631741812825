import { Clock, Flame } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const RenderMarketCard = ({ market, index }) => {
	const navigate = useNavigate();
	let { title } = market;

	try {
		if (title.includes('{')) {
			const resolvedData = JSON.parse(title);
			title = resolvedData.name || title;
		}
	} catch (error) {
		console.error('Error parsing market title:', error);
	}

	const handleSectionClick = (e) => {
		navigate(`/predict/${market.address}`);
	};

	return (
		<div
			key={market.id || index}
			onClick={handleSectionClick}
			className='bg-black border border-yellow-500/20 rounded-lg p-4 hover:border-yellow-500/40 transition-colors min-w-[400px] w-1/3 cursor-pointer'
		>
			<div className='flex items-start justify-between mb-2'>
				<h3 className='font-semibold'>{title}</h3>
				<div className='flex items-center space-x-2'>
					{index === 0 && (
						<span className='flex items-center text-orange-500'>
							<Flame className='w-4 h-4' />
						</span>
					)}
					{market.endingSoon && (
						<span className='text-xs px-2 py-1 bg-red-500/20 text-red-400 rounded'>
							Ending Soon
						</span>
					)}
				</div>
			</div>
			<div className='flex items-center text-sm text-gray-400'>
				<Clock className='w-4 h-4 mr-1' />
				Ends {new Date(market.date).toLocaleDateString()}
			</div>
		</div>
	);
};

export default RenderMarketCard;
