import { ethers } from 'ethers';
import { TOKEN_CONTRACT_ABI } from '../config/abi/token.abi';
import { CHAIN_CONFIG, WOODS_TOKEN } from '../config/config.wods';

export const fetchPriceData = async () => {
	const API_RESPONSE = await fetchBaseTokenPrice();
	// console.log(API_RESPONSE);
	return API_RESPONSE;
};

async function fetchBaseTokenPrice() {
	try {
		// DexScreener API endpoint for Base network
		const response = await fetch(`https://api.dexscreener.com/latest/dex/tokens/${WOODS_TOKEN}`);

		const tokenData = await fetchTokenData();

		if (!response.ok) {
			throw new Error('DexScreener API request failed');
		}

		const data = await response.json();

		// Check if pairs exist
		if (!data.pairs || data.pairs.length === 0) {
			throw new Error('No trading pairs found for this token');
		}

		// Find the most liquid pair (usually the first one)
		const mainPair = data.pairs[0];
		return {
			price: parseFloat(mainPair.priceUsd),
			liquidity: parseFloat(mainPair.liquidity.usd),
			volume24h: parseFloat(mainPair.volume.h24),
			priceChange24h: parseFloat(mainPair.priceChange.h24),
			pair: mainPair.pairAddress,
			marketCap: mainPair.marketCap,
			...tokenData,
			success: true,
		};
	} catch (error) {
		return {
			price: null,
			success: false,
			error: error.message || 'Unable to fetch token price',
		};
	}
}

async function fetchTokenData() {
	try {
		const RPC_URL = CHAIN_CONFIG.rpcUrls[0];
		const provider = new ethers.JsonRpcProvider(RPC_URL);
		const tokenContract = new ethers.Contract(WOODS_TOKEN, TOKEN_CONTRACT_ABI, provider);

		// Fetch block range
		// const latestBlock = await provider.getBlockNumber();
		// const step = 10; // Max range supported by the RPC provider
		// const transferEventFilter = tokenContract.filters.Transfer(null, null);
		// const holdersSet = new Set();

		// Fetch total supply
		const totalSupply = await tokenContract.totalSupply();
		const formattedTotalSupply = ethers.formatUnits(totalSupply, 18);

		return {
			holders: null,
			totalSupply: formattedTotalSupply,
		};
	} catch (error) {
		console.error('Error fetching token data:', error.message);
		return {
			holders: null,
			totalSupply: null,
		};
	}
}
