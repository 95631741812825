import React, { useRef } from 'react';
import { useDraggable } from '../../../../hooks/useDraggable';

export const DraggableItem = ({
	children,
	initialPosition = { x: 0, y: 0 },
	scale = 1,
	className = '',
}) => {
	const elementRef = useRef(null);
	const { position, isDragging, handlers } = useDraggable(initialPosition);

	return (
		<div
			ref={elementRef}
			className={`draggable-item ${className}`}
			style={{
				position: 'absolute',
				left: position.x,
				top: position.y,
				cursor: isDragging ? 'grabbing' : 'grab',
				transform: `scale(${scale})`,
				transformOrigin: 'top left',
				zIndex: isDragging ? 1000 : 1,
			}}
			{...handlers}
		>
			{children}
		</div>
	);
};
