import { Loader } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import {
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import { generateImageUrl } from '../../../../config/helper-functions';
import { fetchGraphDataForMarket } from '../../../../services/api.fetchGraphData';
import { fetchPredictionByContractId } from '../../../../services/api.fetchPredictionByContract';
import { StoreNames } from '../../../../store/config';
import {
    setDataType,
    setGraphData,
    setGraphLoading,
    setGraphOutcomeNames,
    setLoadingAddress,
    setMarketData,
    setSelectedMarket,
} from '../../../../store/reducers/marketReducers';
import { VotingCard } from './VotingCard';
import DynamicHelmet from '../../HelmetComp/HelmetComp';

const outcomeColors = {
    outcomeName1: '#15803e', // Green
    outcomeName2: '#991b1b', // Red
};

const MarketPage = () => {
    const { address } = useParams();
    const dispatch = useDispatch();
    const {
        markets,
        selectedMarket: data,
        graphLoading,
        dataType,
        graphData,
        graphOutcomeNames,
        loadingAddress,
    } = useSelector((state) => state[StoreNames.MARKET]);

    const [selectedIndex, setSelectedIndex] = useState(null);

    const fetchMarket = async () => {
        dispatch(setLoadingAddress(true));
        try {
            const marketData = await fetchPredictionByContractId(address);
            dispatch(
                setMarketData({
                    ...marketData,
                    image: generateImageUrl(
                        marketData.category,
                        address,
                        marketData.image
                    ),
                })
            );
        } catch (err) {
            console.error(err);
            dispatch(setMarketData(null));
        } finally {
            dispatch(setLoadingAddress(false));
        }
    };

    const changeGraphData = async (type) => {
        dispatch(setDataType(type));
        if (!address) return;
        dispatch(setGraphLoading(true));
        try {
            const fetchedData = await fetchGraphDataForMarket(address, type);
            if (fetchedData) {
                const outcomeName1 =
                    fetchedData.outcomeName1?.[0] || 'Outcome 1';
                const outcomeName2 =
                    fetchedData.outcomeName2?.[0] || 'Outcome 2';

                const formattedData = fetchedData.graphData.map((item) => ({
                    date: item.date,
                    [outcomeName1]: item['Outcome 1'],
                    [outcomeName2]: item['Outcome 2'],
                }));

                dispatch(setGraphData(formattedData));
                dispatch(
                    setGraphOutcomeNames({
                        outcomeName1,
                        outcomeName2,
                    })
                );
            }
        } catch (err) {
            console.error('Error fetching graph data:', err);
        } finally {
            dispatch(setGraphLoading(false));
        }
    };

    useEffect(() => {
        if (address) {
            if (markets.length === 0) {
                fetchMarket();
            } else {
                dispatch(setSelectedMarket(address));
            }
        }
    }, [address, markets]);

    useEffect(() => {
        changeGraphData(dataType);
    }, [dataType]);

    if (loadingAddress) {
        return (
            <div className="h-screen text-center pt-[10%]">
                Please Wait, Loading Data....
            </div>
        );
    }

    if (data === null) {
        return (
            <div className="min-h-[60vh] text-lg text-center pt-[10%]">
                Market not found
            </div>
        );
    }

    return (
        <>
            <DynamicHelmet {...data} />
            <div className="container mx-auto flex items-start lg:flex-row flex-col justify-between gap-4 px-6 py-8">
                <div className="flex-3 flex flex-col md:gap-4 gap-6 w-full max-w-4xl">
                    <div>
                        <div className="font-semibold mb-2 text-xl">
                            {data.title}
                        </div>
                        <div className="text-sm text-gray-300 leading-relaxed">
                            {data.description}
                        </div>
                    </div>
                    <div className="w-full relative">
                        <div className="absolute top-4 right-4 z-10 flex gap-0 rounded-2xl border-yellow-400 border px-4 py-1">
                            <button
                                className={`transition-all duration-300 px-4 ${
                                    dataType === '24_hours'
                                        ? 'text-yellow-400'
                                        : 'hover:text-yellow-600 text-white'
                                } border-r-2 border-yellow-400`}
                                onClick={() => changeGraphData('24_hours')}
                            >
                                Last 24 Hours
                            </button>
                            <button
                                className={`transition-all duration-300 px-4 ${
                                    dataType === '7_days'
                                        ? 'text-yellow-400'
                                        : 'text-white hover:text-yellow-600'
                                }`}
                                onClick={() => changeGraphData('7_days')}
                            >
                                Last 7 Days
                            </button>
                        </div>
                        <ResponsiveContainer
                            width="100%"
                            height={500}
                            className="bg-[#2A2A2A] py-2 rounded-lg"
                        >
                            {graphLoading ? (
                                <div className="w-full h-full flex items-center justify-center animate-spin">
                                    <Loader className="text-yellow-500" />
                                </div>
                            ) : (
                                <LineChart
                                    width={500}
                                    height={400}
                                    data={graphData}
                                >
                                    <CartesianGrid
                                        strokeDasharray="3 3"
                                        vertical={false}
                                        stroke="#4A4A4A"
                                    />
                                    <XAxis
                                        dataKey="date"
                                        tick={{ fill: '#fff', fontSize: 12 }}
                                        tickMargin={10}
                                    />
                                    <YAxis
                                        tickFormatter={(value) =>
                                            `${value.toFixed(0)}%`
                                        }
                                        tick={{ fill: '#fff', fontSize: 12 }}
                                        tickMargin={10}
                                    />
                                    <Tooltip
                                        formatter={(value) => `${value}%`}
                                        contentStyle={{
                                            backgroundColor: '#302C38',
                                            padding: '10px',
                                            border: '1.5px solid #E6CBCB29',
                                            borderRadius: '5px',
                                            color: '#fff',
                                        }}
                                    />
                                    <Legend wrapperStyle={{ color: '#fff' }} />
                                    <Line
                                        type="monotone"
                                        dataKey={graphOutcomeNames.outcomeName1}
                                        stroke={outcomeColors.outcomeName1}
                                        strokeWidth={2}
                                        dot={false}
                                    />
                                    <Line
                                        type="monotone"
                                        dataKey={graphOutcomeNames.outcomeName2}
                                        stroke={outcomeColors.outcomeName2}
                                        strokeWidth={2}
                                        dot={false}
                                    />
                                </LineChart>
                            )}
                        </ResponsiveContainer>
                    </div>

                    <div className="flex items-end justify-end text-sm text-yellow-400">
                        <span className="flex items-center">
                            Created by: &nbsp;
                        </span>
                        <Link
                            to="#"
                            onClick={() =>
                                window.open(
                                    `https://basescan.org/address/${data.admin}`,
                                    '_blank'
                                )
                            }
                            className="underline"
                        >
                            {data.admin
                                ? `${data.admin.slice(
                                      0,
                                      4
                                  )}...${data.admin.slice(-3)}`
                                : 'N/A'}
                        </Link>
                    </div>
                </div>

                <div className="flex-4 mx-auto">
                    <VotingCard
                        data={data}
                        selectedIndex={selectedIndex}
                        setSelectedIndex={setSelectedIndex}
                    />
                </div>
            </div>
        </>
    );
};

export default MarketPage;
