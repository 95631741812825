import React from 'react';
import { ScaleControl } from './ScaleControl';

const TextControl = ({ label, value, onChange, scale, onScale }) => {
	return (
		<div className='space-y-2'>
			<div className='flex items-center justify-between'>
				<label className='text-white text-sm'>{label}</label>
				<div className='flex-1 ml-4'>
					<ScaleControl scale={scale} onChange={onScale} />
				</div>
			</div>
			<input
				type='text'
				value={value}
				onChange={(e) => onChange(e.target.value)}
				placeholder={label}
				className='w-full px-4 py-2 bg-gray-800 text-white rounded focus:ring-2 focus:ring-yellow-400'
			/>
		</div>
	);
};

export default TextControl;
