import { ethers } from 'ethers';
import { PREDICTO_ABI } from '../config/abi/predicto.abi';

export const withdrawFunds = async (address) => {
	try {
		if (!window.ethereum) {
			return;
		}
		const provider = new ethers.BrowserProvider(window.ethereum);
		const signer = await provider.getSigner();
		const withdrawContractInstace = new ethers.Contract(address, PREDICTO_ABI, signer);
		const withdrawTrx = await withdrawContractInstace.withdrawWinnings();
		const receipt = await withdrawTrx.wait();

		return receipt?.hash;
	} catch (err) {
		console.log(err);
	}
};
