import { useCallback, useEffect, useState } from 'react';

export const useDraggable = (initialPosition) => {
	const [position, setPosition] = useState(initialPosition);
	const [isDragging, setIsDragging] = useState(false);
	const [elementOffset, setElementOffset] = useState({ x: 0, y: 0 });

	const handleStart = useCallback(
		(clientX, clientY) => {
			setIsDragging(true);
			setElementOffset({
				x: clientX - position.x,
				y: clientY - position.y,
			});
		},
		[position]
	);

	const handleMove = useCallback(
		(clientX, clientY) => {
			if (!isDragging) return;

			setPosition({
				x: clientX - elementOffset.x,
				y: clientY - elementOffset.y,
			});
		},
		[isDragging, elementOffset]
	);

	const handleEnd = useCallback(() => {
		setIsDragging(false);
	}, []);

	const onMouseDown = useCallback(
		(e) => {
			e.preventDefault();
			handleStart(e.clientX, e.clientY);
		},
		[handleStart]
	);

	const onMouseMove = useCallback(
		(e) => {
			e.preventDefault();
			handleMove(e.clientX, e.clientY);
		},
		[handleMove]
	);

	const onTouchStart = useCallback(
		(e) => {
			const touch = e.touches[0];
			handleStart(touch.clientX, touch.clientY);
		},
		[handleStart]
	);

	const onTouchMove = useCallback(
		(e) => {
			const touch = e.touches[0];
			handleMove(touch.clientX, touch.clientY);
		},
		[handleMove]
	);

	useEffect(() => {
		if (!isDragging) return;

		const handleGlobalMove = (e) => {
			if (e instanceof MouseEvent) {
				handleMove(e.clientX, e.clientY);
			} else {
				handleMove(e.touches[0].clientX, e.touches[0].clientY);
			}
		};

		const handleGlobalEnd = () => {
			handleEnd();
		};

		window.addEventListener('mousemove', handleGlobalMove);
		window.addEventListener('mouseup', handleGlobalEnd);
		window.addEventListener('touchmove', handleGlobalMove);
		window.addEventListener('touchend', handleGlobalEnd);

		return () => {
			window.removeEventListener('mousemove', handleGlobalMove);
			window.removeEventListener('mouseup', handleGlobalEnd);
			window.removeEventListener('touchmove', handleGlobalMove);
			window.removeEventListener('touchend', handleGlobalEnd);
		};
	}, [isDragging, handleMove, handleEnd]);

	return {
		position,
		isDragging,
		handlers: {
			onMouseDown,
			onMouseMove,
			onTouchStart,
			onTouchMove,
			onTouchEnd: handleEnd,
		},
	};
};
