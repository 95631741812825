import md5 from 'blueimp-md5';
import React, { useEffect, useId, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useOutlet } from 'react-router-dom';
import { PINATA_GATEWAY } from '../../../config/config.wods';
import { fetchPriceData } from '../../../services/api.fetchBalance';
import { fetchPredictions } from '../../../services/api.fetchPredictions';
import { StoreNames } from '../../../store/config';
import { setAddress } from '../../../store/reducers/addressReducers';
import {
    setFilteredMarkets,
    setMarketLoading,
    setMarkets,
} from '../../../store/reducers/marketReducers';
import Categories from './components/Categories';
import MarketGrid from './components/MarketGrid';
import TokenInfo from './components/TokenInfo';
import TrendingMarkets from './components/TrendingMarkets';

// Utility function to get Gravatar URL
const getGravatarUrl = (category, size = 200, id) => {
    const hash = md5(category.toLowerCase().trim() + id);
    return `https://www.gravatar.com/avatar/${hash}?d=identicon&s=${size}`;
};

// Main App Component
function Campaign() {
    const id = useId();
    const outlet = useOutlet();
    const dispatch = useDispatch();
    const { markets, loadingMarkets, filteredMarkets } = useSelector(
        (state) => state[StoreNames.MARKET]
    );
    const [tokenData, setTokenData] = useState({
        price: null,
        priceChange: null,
        loading: true,
        error: null,
    });
    const [isSelectedCategory, setSelectedCategory] = useState('All');

    // Fetch predictions and process the data
    useEffect(() => {
        if (markets.length !== 0) return;
        const fetchData = async () => {
            try {
                const result = await fetchPredictions();
                const processedData = result.reverse().map((data) => {
                    let title = data.title;
                    let description = data.title;
                    let category = data?.category || 'crypto';
                    let image = getGravatarUrl(category, 200, id);

                    if (data.title.includes('{')) {
                        try {
                            const parsedData = JSON.parse(data.title);
                            title = parsedData.name;
                            description = parsedData.description;
                            category = parsedData.category;
                            if (parsedData['image-hash']) {
                                image = `https://${PINATA_GATEWAY}/ipfs/${parsedData['image-hash']}`;
                            }
                        } catch (error) {
                            console.error('Error parsing data title:', error);
                        }
                    }

                    return { ...data, title, description, image, category };
                });

                dispatch(setMarkets(processedData)); // Initialize filtered data
            } catch (error) {
                console.error('Error fetching predictions:', error);
            } finally {
                dispatch(setMarketLoading(false)); // Set loading to false after prediction data is fetched
            }
        };

        fetchData();
    }, [dispatch, id]);

    // Fetch token data and address
    useEffect(() => {
        const fetchTokenData = async () => {
            try {
                const TOKEN_DATA = await fetchPriceData();
                setTokenData({
                    price: TOKEN_DATA?.price || 'N/A',
                    priceChange: TOKEN_DATA?.priceChange24h || 0,
                    loading: false,
                    error: null,
                    ...TOKEN_DATA,
                });
            } catch (error) {
                setTokenData((prev) => ({
                    ...prev,
                    loading: false,
                    error: error.message || 'Error fetching token data',
                }));
                console.error('Error fetching token data:', error);
            }
        };

        const fetchAddress = async () => {
            try {
                if (!window.ethereum) {
                    throw new Error(
                        'MetaMask is not installed. Please install MetaMask to connect your wallet.'
                    );
                }
                const accounts = await window.ethereum.request({
                    method: 'eth_requestAccounts',
                });
                dispatch(setAddress(accounts[0]));
            } catch (error) {
                console.error('Error fetching address:', error);
            }
        };

        fetchTokenData();
        fetchAddress();
    }, [dispatch]);

    // Filter predictions by category
    const filterPredictionDataByCategory = (selectedCategory) => {
        if (!selectedCategory || selectedCategory === 'All') {
            setSelectedCategory('All');
            dispatch(setMarkets(markets));
        } else {
            setSelectedCategory(selectedCategory);
            dispatch(
                setFilteredMarkets(
                    markets.filter(
                        (data) =>
                            data.category === selectedCategory.toLowerCase()
                    )
                )
            );
        }
    };

    return (
        <>
            {/* <Helmet>
				<title>Wolf of Dumb Street ($WODS)</title>
				<meta name='description' content='Wolf of Dumb Street - Predict and Win the Dumb Money' />
			</Helmet> */}
            <Toaster
                position="top-center"
                reverseOrder={false}
                gutter={8}
                toastOptions={{
                    duration: 5000,
                    style: { background: '#363636', color: '#fff' },
                    success: { duration: 3000 },
                }}
            />
            <div className="min-h-screen bg-[#0D0D0D] text-white no-scrollbar">
                {outlet ? (
                    outlet
                ) : (
                    <main className="container mx-auto px-4 py-6 space-y-8">
                        <TrendingMarkets tokenData={tokenData} />
                        <Categories
                            isSelectedCategory={isSelectedCategory}
                            filterPredictionDataByCategory={
                                filterPredictionDataByCategory
                            }
                        />
                        {loadingMarkets ? (
                            <div className="flex justify-center items-center text-lg text-white">
                                Loading data, please wait...
                            </div>
                        ) : (
                            <>
                                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-3 w-full justify-center">
                                    {filteredMarkets.map((elem, index) => (
                                        <MarketGrid
                                            key={`market-${index}`}
                                            data={elem}
                                        />
                                    ))}
                                </div>
                            </>
                        )}
                        <TokenInfo tokenData={tokenData} />
                    </main>
                )}
            </div>
        </>
    );
}

export default Campaign;
