import { ethers, formatUnits } from 'ethers';
import { ADMIN_FACTORY_ABI } from '../config/abi/admin.abi';
import { PREDICTO_ABI } from '../config/abi/predicto.abi';
import {
    FACTORY_CONTRACT_ADDRESS,
    FETCH_TRANSACTION_RPC,
} from '../config/config.wods';

const BATCH_SIZE = 5; // Adjust batch size based on network conditions

export const fetchPredictions = async () => {
    const provider = new ethers.JsonRpcProvider(FETCH_TRANSACTION_RPC);
    const contractInstance = new ethers.Contract(
        FACTORY_CONTRACT_ADDRESS,
        ADMIN_FACTORY_ABI,
        provider
    );

    const marketAddresses = await contractInstance.getAllMarkets();
    const marketData = [];

    // Split market addresses into batches
    const addressBatches = Array.from(
        { length: Math.ceil(marketAddresses.length / BATCH_SIZE) },
        (_, i) => marketAddresses.slice(i * BATCH_SIZE, (i + 1) * BATCH_SIZE)
    );

    // Fetch data for all batches in parallel
    const results = await Promise.all(
        addressBatches.map(async (batch) => {
            return Promise.all(
                batch.map(async (addr) => {
                    try {
                        const predictionInstance = new ethers.Contract(
                            addr,
                            PREDICTO_ABI,
                            provider
                        );

                        // Use multicall if applicable, otherwise fetch data one by one
                        const [marketResponse, outcomes1, outcomes2] =
                            await Promise.all([
                                predictionInstance.market(),
                                predictionInstance.outcomes(0),
                                predictionInstance.outcomes(1),
                            ]);

                        const minPrice = Number(
                            formatUnits(marketResponse.minPrice, 18)
                        );
                        const maxPrice = Number(
                            formatUnits(marketResponse.maxPrice, 18)
                        );

                        const finalOutcome = marketResponse[13]
                            ? parseInt(marketResponse[12])
                            : null;

                        const finalVal =
                            finalOutcome !== null
                                ? await predictionInstance.outcomes(
                                      finalOutcome
                                  )
                                : null;

                        const participate =
                            finalOutcome !== null
                                ? await predictionInstance.getParticipants(
                                      finalOutcome
                                  )
                                : null;

                        return {
                            admin: marketResponse[0],
                            title: marketResponse[2],
                            options1: outcomes1,
                            options2: outcomes2,
                            minPrice,
                            maxPrice,
                            date: new Date(parseInt(marketResponse[10]) * 1000),
                            total_bets: parseInt(marketResponse[8]),
                            total_rewards: parseInt(marketResponse[7]) / 1e18,
                            address: addr,
                            finalOutcome: finalVal ? finalVal[0] : null,
                            participate: participate ? participate : [],
                            outcomePercentage1:
                                (parseInt(outcomes1[2]) /
                                    parseInt(marketResponse[8])) *
                                100,
                            outcomePercentage2:
                                (parseInt(outcomes2[2]) /
                                    parseInt(marketResponse[8])) *
                                100,
                        };
                    } catch (err) {
                        console.error(`Error processing address ${addr}:`, err);
                        return null; // Skip invalid or failed address
                    }
                })
            );
        })
    );

    // Flatten the results and filter out nulls
    results.forEach((batch) => {
        marketData.push(...batch.filter(Boolean));
    });

    return marketData;
};
