import React from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './store/store';
import './style/index.scss';

const rootElement = document.getElementById('root');

const PortfolioApp = () => (
    <Provider store={store}>
        <HelmetProvider>
            <App />
        </HelmetProvider>
    </Provider>
);

if (rootElement?.hasChildNodes()) {
    hydrateRoot(rootElement, <PortfolioApp />);
} else {
    createRoot(rootElement).render(<PortfolioApp />);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
