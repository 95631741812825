import { configureStore } from '@reduxjs/toolkit';
import { communitiesApi } from './api/communitiesAPI';
import { StoreNames } from './config';
import { default as addressReducers } from './reducers/addressReducers';
import { default as marketReducers } from './reducers/marketReducers';

export const store = configureStore({
	reducer: {
		[communitiesApi.reducerPath]: communitiesApi.reducer,
		[StoreNames.MARKET]: marketReducers,
		[StoreNames.ADDRESS]: addressReducers,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
});
