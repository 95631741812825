import { CHAIN_CONFIG } from '../config/config.wods';

export const BASE_NETWORK_PARAMS = CHAIN_CONFIG;

export const connectWallet = async () => {
    if (!window.ethereum) {
        throw new Error(
            'MetaMask is not installed. Please install MetaMask to connect your wallet.'
        );
    }

    try {
        // Check if a wallet is already connected (from localStorage)
        const persistedWalletAddress = localStorage.getItem('walletAddress');
        if (persistedWalletAddress) {
            // Validate if the wallet is still connected
            const accounts = await window.ethereum.request({
                method: 'eth_accounts',
            });

            if (accounts.includes(persistedWalletAddress)) {
                // Ensure the correct network is selected
                const currentChainId = await window.ethereum.request({
                    method: 'eth_chainId',
                });

                if (currentChainId !== BASE_NETWORK_PARAMS.chainId) {
                    await window.ethereum.request({
                        method: 'wallet_switchEthereumChain',
                        params: [{ chainId: BASE_NETWORK_PARAMS.chainId }],
                    });
                }

                // Return the persisted wallet address
                return { walletAddress: persistedWalletAddress };
            } else {
                // Wallet is no longer connected; clear persisted state
                localStorage.removeItem('walletAddress');
            }
        }

        // If no wallet is connected or persisted, request a connection
        const accounts = await window.ethereum.request({
            method: 'eth_requestAccounts',
        });

        // Check the current chainId
        const currentChainId = await window.ethereum.request({
            method: 'eth_chainId',
        });

        // If we're not on the Base network, switch networks
        if (currentChainId !== BASE_NETWORK_PARAMS.chainId) {
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: BASE_NETWORK_PARAMS.chainId }],
            });
        }

        // Persist the wallet address and return it
        const walletAddress = accounts[0];
        localStorage.setItem('walletAddress', walletAddress);
        return { walletAddress };
    } catch (error) {
        if (error.code === 4902) {
            // Network is not available, try adding it
            try {
                await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [BASE_NETWORK_PARAMS],
                });
            } catch (addError) {
                console.error('Error adding network:', addError);
                throw new Error(
                    'Failed to add Base network. Please try again later.'
                );
            }
        } else if (error.code === 4001) {
            // User rejected connection request
            throw new Error('User rejected the connection request.');
        } else {
            console.error('Error during wallet connection:', error);
            throw new Error('Failed to connect wallet. Please try again.');
        }
    }
};
