import React from 'react';
import CampaignFormUI from './components/createForm';

const CampaignForm = () => {
	return (
		<div className='min-h-screen bg-black'>
			<div className='container mx-auto'>
				<CampaignFormUI />
			</div>
		</div>
	);
};

export default CampaignForm;
