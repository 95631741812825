import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { generateImageUrl } from '../../../../../config/helper-functions';
import { fetchPredictions } from '../../../../../services/api.fetchPredictions';
import { StoreNames } from '../../../../../store';
import { MarketGrid } from './MarketGrid';

const AnnounceCampaign = () => {
	const { address } = useSelector((state) => state[StoreNames.ADDRESS]);
	const [campaignData, setCampaignData] = useState([]);
	const [isLoading, setLoading] = useState(true);

	const fetchCampaignData = async () => {
		setLoading(true); // Explicitly set loading state
		try {
			const result = await fetchPredictions();

			const processedData = result.reverse().map((data) => {
				const { title: rawTitle = '', category: rawCategory = 'crypto' } = data;

				let title = rawTitle;
				let description = rawTitle;
				let category = rawCategory;
				let image;

				// Parse the title if it includes a JSON object
				if (rawTitle.includes('{')) {
					try {
						const parsedData = JSON.parse(rawTitle);
						title = parsedData.name || rawTitle;
						description = parsedData.description || rawTitle;
						category = parsedData.category || rawCategory;
						image = generateImageUrl(category, address, parsedData['image-hash']);
					} catch (error) {
						console.error('Error parsing campaign title:', error);
					}
				}

				// Default image if not set during parsing
				image = image || generateImageUrl(category, address);

				return { ...data, title, description, image, category };
			});

			setCampaignData(processedData);
		} catch (error) {
			console.error('Error fetching campaign data:', error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchCampaignData();
	}, []);

	// Memoize filtered user campaigns
	const userCampaigns = useMemo(
		() =>
			campaignData.filter(
				(campaign) => address && campaign.admin?.toLowerCase() === address.toLowerCase()
			),
		[campaignData, address]
	);

	if (isLoading) {
		return <div>Loading data, please wait...</div>;
	}

	if (!address) {
		return <div>Please connect your wallet.</div>;
	}

	if (!userCampaigns.length) {
		return <div>No data found</div>;
	}

	return (
		<div className='grid grid-cols-3 gap-3'>
			{userCampaigns.map((campaign) => (
				<div key={campaign.id || campaign.admin}>
					<MarketGrid data={campaign} type='announce' />
				</div>
			))}
		</div>
	);
};

export default AnnounceCampaign;
