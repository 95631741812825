import { TrendingUp } from 'lucide-react';
import React from 'react';
import { FaDollarSign } from 'react-icons/fa6';
import { FiExternalLink } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import CONFIG from '../../../../config/config.wods';
import { StoreNames } from '../../../../store';
import RenderMarketCard from './TrendingMarketCards';

const TrendingMarkets = ({ tokenData }) => {
	const { markets: predictionData } = useSelector((state) => state[StoreNames.MARKET]);

	return (
		<section className='py-6'>
			{/* Header */}
			<div className='flex items-center justify-between mb-4'>
				<div className='flex items-center'>
					<div className='flex flex-col'>
						<h2 className='text-xl font-bold flex align-middle  items-center'>
							<TrendingUp className='w-5 h-5 text-yellow-500 mr-2' />
							Trending Markets
						</h2>
					</div>
				</div>
				<div className='flex flex-col'>
					<div className='button-container flex w-full justify-end'>
						<button
							className='hidden md:flex items-center px-4 py-3 bg-yellow-500/15 rounded-md'
							onClick={() => window.open(CONFIG.BUY_WODS_LINK, '_blank')}
						>
							Buy
							<FaDollarSign />
							WODS&nbsp;
							<FiExternalLink />
						</button>
					</div>
					<div className=''>
						{tokenData?.loading ? (
							<span className='text-gray-400'>Loading...</span>
						) : tokenData?.error ? (
							<span className='text-red-400'>{tokenData.error}</span>
						) : (
							<>
								<span className='text-yellow-500'>$WODS:</span>
								<span className='ml-2'>$ {tokenData.price}</span>
								<span
									className={`ml-2 ${
										tokenData.priceChange > 0 ? 'text-green-400' : 'text-red-400'
									}`}
								>
									{tokenData.priceChange > 0 ? '+' : ''}
									{tokenData.priceChange}%
								</span>
							</>
						)}
					</div>
				</div>
			</div>

			{/* Markets Grid */}
			<div className='flex flex-row gap-4 overflow-x-scroll no-scrollbar'>
				{predictionData?.slice(0, 3).map((market, index) => (
					<RenderMarketCard market={market} index={index} />
				))}
			</div>
		</section>
	);
};

export default TrendingMarkets;
